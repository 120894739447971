export const isBrowser = (): boolean => {
  return !(typeof window === 'undefined');
};

export const isFree = (amount: string | number): boolean => {
  return Number(amount) === 0;
};

export const getParameterByName = (name: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getDisabledButtonProps = (buttonEnabled: boolean) => {
  if (buttonEnabled) {
    return {};
  }
  return { color: '#8F8FA1', bgColor: '#D6D6DC' };
};

export const convertDate = (eventDate: string | number | Date) => {
  const date = new Date(eventDate);
  const yr = date.getFullYear();
  const mm = date.toLocaleDateString('en-US', { month: 'short' });
  const dd = date.getDate();

  let dateIndicator: string;
  if (10 <= dd % 100 && dd % 100 <= 200) {
    dateIndicator = 'th';
  } else if (dd % 10 === 1) dateIndicator = 'st';
  else if (dd % 10 === 2) dateIndicator = 'nd';
  else if (dd % 10 === 3) dateIndicator = 'rd';
  else dateIndicator = 'th';

  return `${dd}${dateIndicator} ${mm} ${yr}`;
};

export const convert24To12 = (time24: { split: (arg0: string) => [any, any] }) => {
  const [hrs, mins] = time24.split(':');
  const numericHrs = parseInt(hrs, 10);

  const meridian = numericHrs >= 12 ? 'PM' : 'AM';
  const convertedHrs = hrs % 12 || 12;
  return `${convertedHrs}:${mins} ${meridian}`;
};

export const isNewCardFlow = () => {
  if(isBrowser()) {
    return new URLSearchParams(window.location.search).get('newFlow');
  }
};
