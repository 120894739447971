import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Divider,
  Button,
  chakra,
  InputGroup,
  Link,
  Checkbox,
  Text,
  Textarea,
  RadioGroup,
  CheckboxGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import './index.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSmartPageQueryData } from '../../hooks';
import { ArrowBackIcon, WhatsAppIcon } from '@instamojo/icons';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isFree } from '../../utils';
import { FIELD_TYPE } from './constants';

const phoneRegExp = /^[0-9 ()+/-]{7,}$/;

interface CustomerInfoProps {
  handleCustomerInfo: (values: any) => void;
  initValues: any;
  formErrors: any;
  amount: any;
  disabled: boolean;
  disabledButtonProps: Object;
}

interface ParamTypes {
  slug: string;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({
  handleCustomerInfo,
  initValues,
  formErrors,
  amount,
  disabled,
  disabledButtonProps,
}) => {
  const smartPage = useSmartPageQueryData();
  const shouldCollectMoreInfoFromCustomer = smartPage.customer_info_enabled;
  const showDeliveryInfo =
    shouldCollectMoreInfoFromCustomer &&
    (smartPage.customer_info.ask_shipping_address || smartPage.customer_info.ask_gst_details);
  const customFields = smartPage.customer_info?.custom_fields;
  const initialValues = initValues || {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    customFields: {},
    buyer_whatsapp_consent: false,
  };

  let customFieldsValidations = {};
  const { slug } = useParams<ParamTypes>();
  const submitButton = isFree(amount) ? 'Claim for Free' : 'Proceed to pay';

  customFields.forEach((field) => {
    if (shouldCollectMoreInfoFromCustomer) {
      if (!initValues) {
        initialValues.customFields[field.name] = '';
      }
      if (field.required) {
        switch(field.type) {
          case FIELD_TYPE.EMAIL:
            customFieldsValidations[field.name] = Yup.string().email('Invalid email address').required('Required');
            break;
          case FIELD_TYPE.MOBILE_NUMBER:
            customFieldsValidations[field.name] = Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required');
            break;
          case FIELD_TYPE.CHECKBOX:
            customFieldsValidations[field.name] = Yup.lazy(val => (Array.isArray(val) ? Yup.array().of(Yup.string()).min(1, 'Required') : Yup.string().required('Required')));
            break;
          default:
            customFieldsValidations[field.name] = Yup.string().required('Required');
        }
      } else {
        switch(field.type) {
          case FIELD_TYPE.EMAIL:
            customFieldsValidations[field.name] = Yup.string().email('Invalid email address');
            break;
          case FIELD_TYPE.MOBILE_NUMBER:
            customFieldsValidations[field.name] = Yup.string().matches(phoneRegExp, 'Phone number is not valid');
            break;
        }
      }
    }
  });

  const customerInfoValidationSchema = Yup.object({
    firstName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z ]*$/, 'Please use only alphabets'),
    lastName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z ]*$/, 'Please use only alphabets'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
    customFields: Yup.object({
      ...customFieldsValidations,
    }),
  });

  const { values, handleSubmit, handleChange, handleBlur, errors, setErrors, touched, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: customerInfoValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleCustomerInfo(values);
    },
  });

  useEffect(() => {
    if (formErrors !== null) {
      setErrors(formErrors);
    }
  }, [formErrors, setErrors]);

  const areFormErrorsPresent = Object.keys(formErrors || {}).length > 0;

  const handleInputCheckbox = (e, field) => {
    const multiSelectBox = values.customFields[field.name] === '' ? [] : [...values.customFields[field.name]];
    if (multiSelectBox.indexOf(e.target.value) !== -1) {
      multiSelectBox.splice(multiSelectBox.indexOf(e.target.value), 1);
    } else {
      multiSelectBox.push(e.target.value);
    }
    setFieldValue(`customFields.${field.name}`, multiSelectBox);
  };

  const handleCustomFields = (fieldType, field) => {
    switch (fieldType) {
      case FIELD_TYPE.TEXT:
        return (
          <Input
            type="text"
            name={`customFields.${field.name}`}
            onChange={handleChange}
            value={values.customFields?.[field.name]}
            onBlur={handleBlur}
          />
        );
      case FIELD_TYPE.PARAGRAPH:
        return (
          <Textarea
            name={`customFields.${field.name}`}
            onChange={handleChange}
            value={values.customFields?.[field.name]}
            onBlur={handleBlur}
          />
        );
      case FIELD_TYPE.MOBILE_NUMBER:
        return (
          <InputGroup>
            <PhoneInput
              value={values.customFields?.[field.name]}
              placeholder={'Mobile No'}
              country="in"
              onChange={(e) => {
                setFieldValue(`customFields.${field.name}`, `+${e}`);
              }}
              onBlur={handleBlur}
            />
          </InputGroup>
        );
      case FIELD_TYPE.EMAIL:
        return (
          <Input
            type="text"
            name={`customFields.${field.name}`}
            onChange={handleChange}
            value={values.customFields?.[field.name]}
            onBlur={handleBlur}
          />
        );
      case FIELD_TYPE.RADIO:
        return (
          <RadioGroup
            onChange={(e) => {
              setFieldValue(`customFields.${field.name}`, e);
            }}
            sx={{
              '.chakra-radio': {
                marginTop: '0px !important',
                marginBottom: '6px !important'
              },
              '.chakra-radio__control': {
                borderColor: '#b8b8d3'
              }
            }}
          >
            <Stack spacing={5} direction="column">
              {field.options.map((item) => (
                <Radio value={item}>{item}</Radio>
              ))}
            </Stack>
          </RadioGroup>
        );
      case FIELD_TYPE.CHECKBOX:
        return (
          <CheckboxGroup defaultValue={values.customFields?.[field.name]}>
            <Stack direction={['column', 'row']}>
              {field.options.map((item) => (
                <Checkbox 
                  sx={{
                    '.chakra-checkbox__control:focus': {
                      outline: 'none !important',
                      boxShadow: 'none !important'
                    },
                    '.chakra-checkbox__control': {
                      outline: 'none !important',
                      boxShadow: 'none',
                      borderColor: '#b8b8d3'
                    },
                    '.chakra-checkbox__control[data-checked]': {
                      backgroundColor: '#4e4e91 !important',
                      borderColor: '#4e4e91 !important',
                      outline: 'none !important',
                      boxShadow: 'none'
                    },
                }} 
                value={item} onChange={(e) => handleInputCheckbox(e, field)} >
                  {item}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        );
      case FIELD_TYPE.DATE:
        return (
          <Input
            type="date"
            name={`customFields.${field.name}`}
            onChange={handleChange}
            value={values.customFields?.[field.name]}
            onBlur={handleBlur}
          />
        );
      case FIELD_TYPE.DATETIME:
        return (
          <Input
            type="datetime-local"
            name={`customFields.${field.name}`}
            onChange={handleChange}
            value={values.customFields?.[field.name]}
            onBlur={handleBlur}
          />
        );
      default:
        return null;
    }
  };

  return (
    <chakra.form onSubmit={handleSubmit}>
      <Box>
        <Heading fontSize="18" mb="24px">
          Customer Information
        </Heading>

        <Box d="flex" mb="16px" flexDirection={{ base: 'column', md: 'row' }}>
          <FormControl
            w={{ base: '100%', md: '50%' }}
            mr={{ base: '0', md: '16px' }}
            mb={{ base: '16px', md: '0' }}
            isInvalid={Boolean(errors.firstName && touched.firstName)}
          >
            <FormLabel fontSize="14px" mb="4px">
              First Name
              <chakra.span color="red">*</chakra.span>
            </FormLabel>
            <Input type="text" name="firstName" onChange={handleChange} value={values.firstName} onBlur={handleBlur} />
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl w={{ base: '100%', md: '50%' }} isInvalid={Boolean(errors.lastName && touched.lastName)}>
            <FormLabel fontSize="14px" mb="4px">
              Last Name
              <chakra.span color="red">*</chakra.span>
            </FormLabel>
            <Input type="text" name="lastName" onChange={handleChange} value={values.lastName} onBlur={handleBlur} />
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box mb="16px">
          <FormControl isInvalid={Boolean(errors.email && (touched.email || areFormErrorsPresent))}>
            <FormLabel fontSize="14px" mb="4px">
              Email ID
              <chakra.span color="red">*</chakra.span>
            </FormLabel>
            <Input type="email" name="email" onChange={handleChange} value={values.email} onBlur={handleBlur} />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box>
          <FormControl
            w={{ base: '100%', md: '50%' }}
            isInvalid={Boolean(errors.phoneNumber && (touched.email || areFormErrorsPresent))}
          >
            <FormLabel fontSize="14px" mb="4px">
              Mobile No.
              <chakra.span color="red">*</chakra.span>
            </FormLabel>
            <InputGroup>
              <PhoneInput
                value={values.phoneNumber}
                placeholder={'Mobile No'}
                country="in"
                onChange={(e) => {
                  setFieldValue('phoneNumber', e);
                }}
                onBlur={handleBlur}
                inputProps={{autoComplete: 'tel'}}
              />
            </InputGroup>
            <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
          </FormControl>
        </Box>

        {shouldCollectMoreInfoFromCustomer && (
          <Box>
            {customFields.map((field) => {
              return (
                <FormControl
                  w={{ base: '100%', md: '50%' }}
                  isInvalid={Boolean(errors.customFields?.[field.name] && touched.customFields?.[field.name])}
                  mt="16px"
                >
                  <FormLabel fontSize="14px" mb="4px">
                    {field.name}
                    {field.required && <chakra.span color="red">*</chakra.span>}
                  </FormLabel>
                  {handleCustomFields(field.type, field)}

                  <FormErrorMessage>{errors.customFields?.[field.name]}</FormErrorMessage>
                </FormControl>
              );
            })}
          </Box>
        )}
      </Box>

      <Divider my="16px" />
      {smartPage?.ask_buyer_whatsapp_consent && (
        <Box my="16px">
          <FormControl>
            <Checkbox
              isChecked={values.buyer_whatsapp_consent}
              colorScheme="blue"
              onChange={(e) => setFieldValue('buyer_whatsapp_consent', e.target.checked)}
            >
              <Text d="flex" mb="0">
                I'd like to receive order updates on
                <Box mx="4px">
                  <WhatsAppIcon size="24px" />
                </Box>
                <Text as="strong">WhatsApp</Text>
              </Text>
            </Checkbox>
          </FormControl>
        </Box>
      )}
      <Box d="flex" justifyContent="space-between" mb="16px">
        <Link href={`/${slug ? slug : ''}`}>
          <Button
            p="0"
            color="primary"
            d="flex"
            alignItems="center"
            textDecoration="underline"
            variant="ghost"
            leftIcon={<ArrowBackIcon size="18px" />}
          >
            Back
          </Button>
        </Link>
        <Button
          {...disabledButtonProps}
          type="submit"
          disabled={disabled}
          px="24px"
          background="primary"
        >
          {showDeliveryInfo ? 'Add Delivery Info' : submitButton}
        </Button>
      </Box>
    </chakra.form>
  );
};

export default CustomerInfo;
