const Radio = {
  parts: ['container', 'control', 'label'],
  baseStyle: {
    container: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    control: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      borderRadius: '50%',
      boxSizing: 'border-box',
      padding: '3px',
      background: 'transparent',
      outline: 'none',
      _after: {
        content: '""',
        width: '100%',
        height: '100%',
        display: 'block',
        background: 'primary',
        borderRadius: '50%',
        transform: 'scale(0)',
        transition: 'transform 0.1s',
      },
      _before: null,
      _hover: null,
      _focus: null,
      _checked: {
        _after: {
          transform: 'scale(1)',
        },
        _before: null,
        _hover: null,
        _focus: null,
        borderColor: 'primary',
        background: 'transparent',
      },
      _disabled: {
        _after: {
          transform: 'scale(1)',
          background: 'transparent',
        },
        _before: null,
        _hover: null,
        _focus: null,
        borderColor: 'grey',
        _checked: {
          borderColor: 'grey',
          _after: {
            background: 'grey',
          },
        },
        background: 'transparent',
      },
    },
  },
  sizes: {
    md: {
      control: { w: '20px', h: '20px' },
    },
  },
};

export default Radio;
