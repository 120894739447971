const Input = {
  parts: ["field", "addon"],
  sizes: {
    md: {
      field: {
        borderRadius: "3px",
      },
    },
  },
  variants: {
    outline: {
      field: {
        _focus: {
          // boxShadow: "none",
        },
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default Input;
