import axios from 'axios';
import { isBrowser, isFree } from '../utils';
import { trackEvent } from '../utils/analytics';

const isPreviewPage = () => {
  const pathname = window.location.pathname;
  const slug = window.location.pathname.split('/')[1];
  return pathname === '/' + slug + '/preview';
};

const getSmartPageApi = async (slug: string) => {
  try {
    slug = axios.defaults.headers.common['x-im-pagename'];
    const { data } = await axios.get(`/webapi/smartpages/${slug}/buyer-view/`);
    return data;
  } catch (error) {
    trackEvent('Failed Offer Page', {
      link_source: 'smart_page',
      offer_id: axios.defaults.headers.common['x-im-pagename'],
      seller_username: axios.defaults.headers.common['x-im-username'],
      seller_account_id: error.response.data?.account_it,
      self_flag: isPreviewPage(),
      error_code: error.response.status,
    });
  }
};

const getProductDetailsFromAbandonedPayment = async ({
  slug,
  orderId,
  setCustomerInfo,
  code,
  setFinalDiscountCode,
  setDeliveryInfo,
  token
}) => {
  try {
    window.localStorage.setItem('orderId', orderId);
    if (code) window.localStorage.setItem('code', code);
    slug = axios.defaults.headers.common['x-im-pagename'];
    const { data } = await axios.get(`/webapi/smartpages/${slug}/pay?order=${orderId}&token=${token}`);
    const codeFromLocalStorage: any = window.localStorage.getItem(orderId);
    const discount =
      !!code && code.length
        ? code
        : !!codeFromLocalStorage && codeFromLocalStorage.length
        ? codeFromLocalStorage
        : !!data?.discount_code && data?.discount_code?.length
        ? data?.discount_code
        : '';
    setFinalDiscountCode(discount);

    const abandonedPaymentCheckoutData = {
      productName: data.product_name,
      productAmount: data.quantity ? (data.amount/data.quantity) : data.amount,
      productQuantity: data.quantity || 1,
      discountCode: discount,
      amount: data.amount,
      finalAmount: data.amount * (data.quantity || 1),
      isDiscountValid: false,
      discountedTotalAmount: null,
      selectedOption: {
        purpose: data.product_name,
        amount: data.quantity ? (data.amount/data.quantity) : data.amount,
        is_active: true,
        is_default: true,
        multiple_quantities_allowed: false,
        discounted_amount: null,
        flexible_amount_allowed: false,
      },
    };

    const deliveryInfoInitialValues = {
      shipping_address: {
        address: data?.shipping_address || '',
        city: data?.shipping_city || '',
        zip: data?.shipping_zip || '',
        state: data?.shipping_state || '',
        country: 'India',
      },
      billing_same_as_shipping: false,
      show_gst_field: true,
      gstin: data?.gstin || '',
      company_name: data?.comapany_name || '',
      ...(data?.billing_address && {
        billing_address: {
          address: data?.billing_address || '',
          city: data?.billing_city || '',
          zip: data?.billing_zip || '',
          state: data?.billing_state || '',
          country: 'India',
        },
      }),
    }

    if (data?.shipping_address) setDeliveryInfo(deliveryInfoInitialValues)

    if (isBrowser()) {
      window.localStorage.setItem('abandonedPaymentCheckoutData', JSON.stringify(abandonedPaymentCheckoutData));
      if (!!code) window.localStorage.setItem(orderId, code);
    }

    const [firstName, lastName] = data?.buyer_name.split(' ');

    setCustomerInfo({
      firstName: firstName,
      lastName: lastName,
      email: data.buyer_email,
      phoneNumber: data.buyer_phone,
      customFields: data?.additional_custom_fields || {},
      buyer_whatsapp_consent: false,
    });

    return {
      data: abandonedPaymentCheckoutData,
    };
  } catch (e) {}
};

const getSmartPagePreviewApi = async ({ slug, token }) => {
  const { data } = await axios.get(`/webapi/smartpages/${slug}/preview?token=${token}`);
  return data;
};

interface IPlaceOrderData {
  slug: string;
  name: string;
  email: string;
  phoneNumber: string;
  productName: string;
  amount: number;
  finalAmount: number;
  discountCode?: string;
  productQuantity: number;
  additionalFields: any;
  buyer_whatsapp_consent: boolean;
}

interface IPlaceOrder {
  orderParams: IPlaceOrderData;
  isWhatsappCheckEligible: boolean;
}

const placeOrderApi = async ({orderParams, isWhatsappCheckEligible}: IPlaceOrder) => {
  let {
    slug,
    name,
    email,
    phoneNumber,
    productName,
    amount,
    finalAmount,
    productQuantity,
    discountCode,
    additionalFields,
    buyer_whatsapp_consent,
  } = orderParams;

  const modifiedAdditionFields = {
    ...additionalFields,
    ...(additionalFields.show_gst_field === false && {
      gstin: '',
      company_name: '',
    }),
  };

  const params = {
    buyer_name: name,
    buyer_email: email,
    buyer_phone: phoneNumber.startsWith('+') ? phoneNumber : '+' + phoneNumber,
    product_name: productName,
    final_amount: +finalAmount,
    amount: +amount,
    discount_code: discountCode,
    quantity: +productQuantity,
    additional_fields: JSON.stringify(modifiedAdditionFields),
    is_free_product: isFree(amount),
      ...(isWhatsappCheckEligible && { buyer_whatsapp_consent: buyer_whatsapp_consent}),
  };
  let formData = new FormData();

  for (let key in params) {
    formData.append(key, params[key]);
  }

  slug = axios.defaults.headers.common['x-im-pagename'];
  const { data } = await axios.post(`/webapi/smartpages/${slug}/pay/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
};

const getSmartPageRedirectionApi = async ({ slug, orderId, token }) => {
  const { data } = await axios.get(`/webapi/smartpages/${slug}/redirection/?order_id=${orderId}&token=${token}`);
  return data;
};

const validateDiscountApi = async ({ slug, code, amount }) => {
  slug = axios.defaults.headers.common['x-im-pagename'];
  const discountPayload = {
    code,
    amount
  }
  let formData = new FormData();
  for (let key in discountPayload) {
    formData.append(key, discountPayload[key]);
  }
  const { data } = await axios.post(`/webapi/smartpages/${slug}/discount/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  if (data.is_valid) {
    window.localStorage.setItem('emailDiscount', code === window.localStorage.getItem('code') ? 'yes' : 'no')
  }
  return data;
};

const getReportAbuseFlagApi = async ({ username }) => {
  const { data } = await axios.get(`/webapi/report-abuse/${username}/status/`);
  return data;
};
const policies = {
  'terms-and-conditions': 'Terms and Conditions',
  'privacy': 'Privacy Policy',
  'cancel-and-refund': 'Cancellation and Refund Policy',
  'shipping-and-delivery': 'Shipping and Delivery Policy',
};
const getPolicyApi = async ({ username }) => {
  const { data } = await axios.get(`/webapi/tos/buyer/${username}/`);
  const tosPolicies = {};
  Object.entries(policies).map(([policyName, policy], index) => {
    if (data[policyName] !== undefined) {
      tosPolicies[index] = {
        name: policy,
        status: !(data[policyName]?.hidden ?? false),
        content: data[policyName]?.content ?? '',
        type: policyName,
        additionalContent: data[policyName]?.additional_content ?? '',
      };
    }
    return null;
  });
  return tosPolicies;
};

const postEnquiryApi = async ({ slug, ...enquiry }) => {
  let formData = new FormData();
  for (let key in enquiry) {
    if (key === 'custom_fields_data') {
      formData.append(key, JSON.stringify(enquiry[key]));
    } else {
      formData.append(key, enquiry[key]);
    }
  }
  const { data } = await axios.post(`/webapi/smartpages/${slug}/enquiry/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};

export {
  getProductDetailsFromAbandonedPayment,
  getSmartPageApi,
  placeOrderApi,
  getSmartPagePreviewApi,
  getSmartPageRedirectionApi,
  validateDiscountApi,
  getReportAbuseFlagApi,
  getPolicyApi,
  postEnquiryApi,
};
