import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Checkbox,
  Divider,
  Button,
  chakra,
  Select,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';
import React from 'react';
import { useSmartPageQueryData } from '../../hooks';
import { ArrowBackIcon } from '@instamojo/icons';
import { isFree } from '../../utils';

const states = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu',
  'Jharkhand',
  'Karnataka',
  'Kashmir',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
];

const pinCodeRegex = /\b[1-9]{1}[0-9]{2}[0-9]{3}\b/;

const addressSchema = Yup.object({
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zip: Yup.string().matches(pinCodeRegex, 'Pincode is not valid').required('Required'),
  state: Yup.string().required('Required'),
});

interface DeliveryInfoProps {
  handleDeliveryInfo: (values: any) => void;
  handleActiveStep: (step: string) => void;
  initValues: any;
  amount: any;
  disabled: boolean;
  disabledButtonProps: Object;
}

const DeliveryInfo: React.FC<DeliveryInfoProps> = ({ handleDeliveryInfo, handleActiveStep, initValues, amount, disabled, disabledButtonProps }) => {
  const smartPage = useSmartPageQueryData();
  const submitButton = isFree(amount) ? 'Claim for free' : 'Proceed to pay';
  const shouldAskShippingAddress = smartPage.customer_info.ask_shipping_address;
  const shouldAskGstDetails = smartPage.customer_info.ask_gst_details;
  const initialValues = initValues || {
    shipping_address: {
      address: '',
      city: '',
      zip: '',
      state: '',
      country: 'India',
    },
    billing_same_as_shipping: false,
    show_gst_field: true,
    gstin: '',
    company_name: '',
    ...(shouldAskGstDetails && {
      billing_address: {
        address: '',
        city: '',
        zip: '',
        state: '',
        country: 'India',
      },
    }),
  };

  const validations: any = {};
  if (shouldAskShippingAddress) {
    validations.shipping_address = addressSchema;
  }
  if (shouldAskGstDetails) {
    validations.billing_address = Yup.object().when('billing_same_as_shipping', {
      is: (billing_same_as_shipping) => !billing_same_as_shipping,
      then: addressSchema,
    });
    validations.gstin = Yup.string().when('show_gst_field', {
      is: (show_gst_field) => show_gst_field,
      then: Yup.string()
        .required('GST Number is Required')
        .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'GST number is invalid'),
    });
  }
  const validationSchema = Yup.object({
    ...validations,
  });

  const { values, handleSubmit, handleChange, handleBlur, errors, touched, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleDeliveryInfo(values);
    },
  });

  return (
    <chakra.form onSubmit={handleSubmit} mb="32px">
      {shouldAskShippingAddress && (
        <Box>
          <Heading fontSize="18" mb="24px">
            Shipping Address
          </Heading>
          <Box mb="16px">
            <FormControl
              isInvalid={Boolean(
                getIn(errors, 'shipping_address.address') && getIn(touched, 'shipping_address.address'),
              )}
            >
              <FormLabel fontSize="14px" mb="4px">
                Address
              </FormLabel>
              <Textarea
                name="shipping_address.address"
                onChange={handleChange}
                value={values.shipping_address.address}
                onBlur={handleBlur}
              />
              <FormErrorMessage>{getIn(errors, 'shipping_address.address')}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box d="flex" mb="24px">
            <FormControl
              isInvalid={Boolean(getIn(errors, 'shipping_address.city') && getIn(touched, 'shipping_address.city'))}
              w="50%"
              mr="16px"
            >
              <FormLabel fontSize="14px" mb="4px">
                City
              </FormLabel>
              <Input
                type="text"
                name="shipping_address.city"
                onChange={handleChange}
                value={values.shipping_address.city}
                onBlur={handleBlur}
              />
              <FormErrorMessage>{getIn(errors, 'shipping_address.city')}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(getIn(errors, 'shipping_address.zip') && getIn(touched, 'shipping_address.zip'))}
              w="50%"
            >
              <FormLabel fontSize="14px" mb="4px">
                Pincode
              </FormLabel>
              <Input
                type="text"
                name="shipping_address.zip"
                onChange={handleChange}
                value={values.shipping_address.zip}
                onBlur={handleBlur}
              />
              <FormErrorMessage>{getIn(errors, 'shipping_address.zip')}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box mb="24px">
            <FormControl
              isInvalid={Boolean(getIn(errors, 'shipping_address.state') && getIn(touched, 'shipping_address.state'))}
              w="50%"
            >
              <FormLabel fontSize="14px" mb="4px">
                State
              </FormLabel>
              <Select
                placeholder="Select State"
                name="shipping_address.state"
                onChange={handleChange}
                value={values.shipping_address.state}
                onBlur={handleBlur}
              >
                {states.map((state) => (
                  <option value={state} key={state}>
                    {state}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{getIn(errors, 'shipping_address.state')}</FormErrorMessage>
            </FormControl>
          </Box>
          <Divider mb="16px" />
        </Box>
      )}

      {shouldAskGstDetails && (
        <Box>
          <Heading fontSize="24" mb="24px">
            Invoice Details
          </Heading>
          <Heading fontSize="18" mb="24px">
            Billing Address
          </Heading>
          {shouldAskShippingAddress && (
            <Box mb="16px">
              <Checkbox
                isChecked={values.billing_same_as_shipping}
                colorScheme="blue"
                onChange={(e) => setFieldValue('billing_same_as_shipping', e.target.checked)}
              >
                Same as Shipping Address
              </Checkbox>
            </Box>
          )}

          {!values.billing_same_as_shipping && (
            <Box>
              <Box mb="16px">
                <FormControl
                  isInvalid={Boolean(
                    getIn(errors, 'billing_address.address') && getIn(touched, 'billing_address.address'),
                  )}
                >
                  <FormLabel fontSize="14px" mb="4px">
                    Address
                  </FormLabel>
                  <Textarea
                    name="billing_address.address"
                    onChange={handleChange}
                    value={values.billing_address.address}
                    onBlur={handleBlur}
                  />
                  <FormErrorMessage>{getIn(errors, 'billing_address.address')}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box mb="16px" d="flex">
                <FormControl
                  isInvalid={Boolean(getIn(errors, 'billing_address.city') && getIn(touched, 'billing_address.city'))}
                  mr="16px"
                >
                  <FormLabel fontSize="14px" mb="4px">
                    City
                  </FormLabel>
                  <Input
                    type="text"
                    name="billing_address.city"
                    onChange={handleChange}
                    value={values.billing_address.city}
                    onBlur={handleBlur}
                  />
                  <FormErrorMessage>{getIn(errors, 'billing_address.city')}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(getIn(errors, 'billing_address.zip') && getIn(touched, 'billing_address.zip'))}
                >
                  <FormLabel fontSize="14px" mb="4px">
                    Pincode
                  </FormLabel>
                  <Input
                    type="text"
                    name="billing_address.zip"
                    onChange={handleChange}
                    value={values.billing_address.zip}
                    onBlur={handleBlur}
                  />
                  <FormErrorMessage>{getIn(errors, 'billing_address.zip')}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box mb="16px">
                <FormControl
                  isInvalid={Boolean(getIn(errors, 'billing_address.state') && getIn(touched, 'billing_address.state'))}
                  w="50%"
                >
                  <FormLabel fontSize="14px" mb="4px">
                    State
                  </FormLabel>
                  <Select
                    placeholder="Select State"
                    name="billing_address.state"
                    onChange={handleChange}
                    value={values.billing_address.state}
                    onBlur={handleBlur}
                  >
                    {states.map((state) => (
                      <option value={state} key={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{getIn(errors, 'billing_address.state')}</FormErrorMessage>
                </FormControl>
              </Box>
            </Box>
          )}

          <Divider my="24px" />

          <Box mb="16px">
            <Checkbox
              isChecked={values.show_gst_field}
              colorScheme="blue"
              onChange={(e) => setFieldValue('show_gst_field', e.target.checked)}
            >
              Use GST number for this payment
            </Checkbox>
          </Box>
          {values.show_gst_field && (
            <Box>
              <FormControl w="50%" mb="16px" isInvalid={Boolean(getIn(errors, 'gstin') && getIn(touched, 'gstin'))}>
                <FormLabel fontSize="14px" mb="4px">
                  GST Number
                </FormLabel>
                <Input type="text" name="gstin" onChange={handleChange} value={values.gstin} onBlur={handleBlur} />
                <FormErrorMessage>{getIn(errors, 'gstin')}</FormErrorMessage>
              </FormControl>
              <FormControl w="50%" mb="16px">
                <FormLabel fontSize="14px" mb="4px">
                  Company Name
                </FormLabel>
                <Input
                  type="text"
                  name="company_name"
                  onChange={handleChange}
                  value={values.company_name}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Box>
          )}
          <Divider my="24px" />
        </Box>
      )}

      <Box d="flex" justifyContent="space-between">
        <Button p="0" color="primary" d="flex" alignItems="center" textDecoration="underline" onClick={() => handleActiveStep('customerInfo')} variant="ghost" leftIcon={<ArrowBackIcon size="18px"/>}>
          Back
        </Button>
        <Button
          {...disabledButtonProps}
          type="submit"
          disabled={disabled}
          px="24px"
          background="primary"
        >
          {submitButton}
        </Button>
      </Box>
    </chakra.form>
  );
};

export default DeliveryInfo;
