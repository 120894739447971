const Button = {
  baseStyle: {
    borderRadius: '4px',
  },
  variants: {
    ghost: {
      color: 'primary',
      bg: 'transparent',
      _hover: null,
      _active: null,
      _focus: null,
    },
    outline: {
      border: '1px solid',
      borderColor: 'primary',
      color: 'primary',
      bg: 'transparent',
      _hover: null,
      _active: null,
      _focus: null,
    },
    solid: {
      color: 'white',
      bg: 'primary',
      _hover: null,
      _active: null,
      _focus: null,
    },
  },
  defaultProps: {
    variant: 'solid',
  },
};

export default Button;
