import { Box, Heading, Text } from '@chakra-ui/react';
import ThemeProvider from '../theme/ThemeProvider';

const PageNotFound = () => (
  <ThemeProvider>
    <Box as="section">
      <Box maxW="2xl" mx="auto" px={{ base: '6', lg: '8' }} py={{ base: '16', sm: '20' }} textAlign="center">
        <Heading as="h2" size="3xl" fontWeight="extrabold" letterSpacing="tight">
          404
        </Heading>
        <Text mt="4" fontSize="lg">
          Page Not Found
        </Text>
      </Box>
    </Box>
  </ThemeProvider>
);

export default PageNotFound;
