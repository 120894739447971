import { chakra, HStack, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PlusIcon, MinusIcon } from '@instamojo/icons';

const Counter = ({ onQuantityChange, item, flexibleAmount }) => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    onQuantityChange(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    setCount(count - 1 < 1 ? 1 : count - 1);
  };

  const {value, current_value, enabled, exceed, type} = item?.goal_tracker || {};
  const isPlusCounterEnabled = enabled && !exceed && value > current_value;
  const goalQty = value === current_value ? value : value - current_value;

  let disableQuantity;
  if(isPlusCounterEnabled) {
    if(item.flexible_amount_allowed && flexibleAmount) {
      if(type === 1) {
        disableQuantity = value > current_value ? Math.trunc((value - current_value)/flexibleAmount) : null;
      } else {
        disableQuantity = goalQty;
      }
    } else if(type === 1) {
      const productAmount = item.discounted_amount ? item.discounted_amount : item.amount;
      disableQuantity = value > current_value ? Math.trunc((value - current_value)/productAmount) : null;
    } else {
      disableQuantity = goalQty;
    }
  }

  return (
    <HStack spacing="2">
      <IconButton
        variant="outline"
        size="xs"
        aria-label="decrease"
        borderRadius="4px"
        disabled={count < 2}
        onClick={decrement}
        borderColor="#ccc"
        icon={<MinusIcon size="16" />}
      />
      <chakra.span fontSize="14" color="primary">
        {count < 100 ? ('00' + count).slice(-2) : count}
      </chakra.span>
      <IconButton
        variant="outline"
        size="xs"
        aria-label="increase"
        borderColor="#ccc"
        onClick={increment}
        icon={<PlusIcon size="16" />}
        borderRadius="4px"
        disabled={count >= disableQuantity}

      />
    </HStack>
  );
};

export default Counter;
