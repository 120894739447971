import { isBrowser } from '../../../utils';
import { Box } from '@chakra-ui/react';
import React, { Suspense } from 'react';

const QuillEditor = React.lazy(() => import('../QuillEditor'));

interface EditorProps {
  value: string;
  isReadOnly?: boolean;
}

const Editor: React.FC<EditorProps> = ({ value, isReadOnly }) => {
  if (!isBrowser()) {
    return (
      <Box className="ql-container ql-bubble ql-disabled">
        <Box
          className="ql-editor"
          font-customisation="para-text"
          color-customisation="page-body-text"
          data-gramm="false"
          contentEditable="false"
        >
          <Box dangerouslySetInnerHTML={{ __html: value }}></Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Suspense fallback={<Box></Box>}>
        <QuillEditor readOnly={isReadOnly} value={value} theme="bubble" />
      </Suspense>
    );
  }
};

export default Editor;
