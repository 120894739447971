import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Heading,
  Textarea,
  Divider,
  chakra,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { useState } from 'react';

import { postEnquiryApi } from '../../api';
import { trackEvent } from '../../utils/analytics';

const phoneRegExp = /\b[1-9]{1}\d{9}\b/;

const defaultFields = {
  order: ['Customer Name', 'Customer Email Address', 'Customer Phone Number', 'Customer Query'],
  map: {
    'Customer Name': { field: 'name', label: 'Full Name' },
    'Customer Query': { field: 'query', label: 'Ask Us' },
    'Customer Email Address': { field: 'email', label: 'Email' },
    'Customer Phone Number': { field: 'phone', label: 'Phone Number' },
  },
  fieldName(name) {
    return defaultFields.map[name]?.field || name;
  },
  labelName(name) {
    return defaultFields.map[name]?.label || name;
  },
  sortFn(a, b) {
    return defaultFields.order.indexOf(a.name) - defaultFields.order.indexOf(b.name);
  },
};

interface EnquiryFormProps {
  enquiryForm: any;
  slug: string;
  accountId: string;
}

const EnquiryForm: React.FC<EnquiryFormProps> = ({ slug, enquiryForm, accountId }) => {
  let { custom_enquiry_fields, default_enquiry_fields, acknowledgement_message } = enquiryForm;
  // show the default fields only if they are required
  default_enquiry_fields = default_enquiry_fields.sort(defaultFields.sortFn).filter((field) => field.required);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { fieldName, labelName } = defaultFields;
  const initEnquiryValues = {};
  let customFieldsValidations = {};

  default_enquiry_fields.forEach((field) => {
    if (!initEnquiryValues[fieldName(field.name)]) {
      initEnquiryValues[fieldName(field.name)] = '';
    }
    if (fieldName(field.name) === 'email' && field.required) {
      customFieldsValidations[fieldName(field.name)] = Yup.string().email('Invalid email address').required('Required');
    } else if (fieldName(field.name) === 'phone' && field.required) {
      customFieldsValidations[fieldName(field.name)] = Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Required');
    } else if (field.required) {
      customFieldsValidations[fieldName(field.name)] = Yup.string().required('Required');
    }
  });

  custom_enquiry_fields.forEach((field) => {
    if (!initEnquiryValues[field.name]) {
      initEnquiryValues[field.name] = '';
    }
    if (field.required) {
      customFieldsValidations[field.name] = Yup.string().required('Required');
    }
  });

  const { mutate: handleEnquiryInfo } = useMutation(postEnquiryApi, {
    onSuccess: () => {
      setIsFormSubmitted(true);
      resetForm();
    },
  });

  const { values, handleSubmit, handleChange, handleBlur, errors, touched, resetForm } = useFormik({
    initialValues: initEnquiryValues,
    validationSchema: Yup.object({
      ...customFieldsValidations,
    }),
    onSubmit: (values) => {
      const customFields = custom_enquiry_fields.map((field) => field.name);
      const enquiryInfoInputs = {
        custom_fields_data: {},
      };

      for (let [key, value] of Object.entries(values)) {
        if (customFields.includes(key)) {
          enquiryInfoInputs.custom_fields_data[key] = value;
        } else {
          enquiryInfoInputs[key] = value;
        }
      }

      handleEnquiryInfo({ slug, user_id: accountId, ...enquiryInfoInputs });

      trackEvent('Submitted Enquiry', {
        enquiry_details: enquiryInfoInputs,
      });
    },
  });
  const fields = [...default_enquiry_fields, ...custom_enquiry_fields];

  if (isFormSubmitted) {
    return (
      <>
        <Box>
          <Heading fontSize="24px" mb="24px">
            Enquiry Form
          </Heading>
          <Text>{acknowledgement_message}</Text>
          <Button onClick={() => setIsFormSubmitted(false)} variant="outline">
            Write Another
          </Button>
        </Box>
        <Divider my="56px" />
      </>
    );
  }

  return (
    <chakra.form onSubmit={handleSubmit}>
      <Box>
        <Heading fontSize="24px" mb="24px" color="primary">
          Enquiry Form
        </Heading>
        <Box>
          {fields.map((field) => (
            <FormControl
              key={field.name}
              isInvalid={Boolean(errors[fieldName(field.name)] && touched[fieldName(field.name)])}
              mt="16px"
            >
              <FormLabel fontSize="14px" mb="4px">
                {labelName(field.name)}
                {field.required && <chakra.span color="red">*</chakra.span>}
              </FormLabel>
              {field.name === 'Customer Query' ? (
                <Textarea
                  name={fieldName(field.name)}
                  onChange={handleChange}
                  value={values[fieldName(field.name)]}
                  onBlur={handleBlur}
                />
              ) : (
                <Input
                  type="text"
                  name={fieldName(field.name)}
                  onChange={handleChange}
                  value={values[fieldName(field.name)]}
                  onBlur={handleBlur}
                />
              )}
              <FormErrorMessage>{errors[fieldName(field.name)]}</FormErrorMessage>
            </FormControl>
          ))}
        </Box>
        <Button type="submit" mt="24px">
          Submit
        </Button>
      </Box>
      <Divider my="56px" />
    </chakra.form>
  );
};
export default EnquiryForm;
