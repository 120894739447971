import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { PlusIcon, MinusIcon } from '@instamojo/icons';

import React from 'react';

import { IFaqs } from '../../types';

interface FaqsProps {
  faqs: IFaqs[];
  faqEnabled: boolean;
}

const Faqs: React.FC<FaqsProps> = ({ faqs, faqEnabled }) => {
  const textColor = useColorModeValue('#696982', 'rgba(255, 255, 255, 0.6)');
  if (!faqEnabled) {
    return null;
  }

  return (
    <Box mb="56px">
      <Heading fontSize="24px" mb="24px" color="primary">
        FAQs
      </Heading>
      <Accordion allowToggle>
        {faqs.map((faq, idx) => {
          return (
            <AccordionItem key={idx}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton px="8px" py="12px">
                    <Box flex="1" textAlign="left" fontSize="16px" fontWeight={isExpanded ? '500' : '400'}>
                      {faq.question}
                    </Box>

                    {isExpanded ? <MinusIcon size="20px" /> : <PlusIcon size="20px" />}
                  </AccordionButton>
                  <AccordionPanel fontSize="14px" px="8px" pb="16px" pt="0" color={textColor}>
                    {faq.answer}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
};

export default Faqs;
