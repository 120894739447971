import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  Button,
  Image,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  StackDivider,
  IconButton,
  HStack,
  FormErrorMessage,
  useDisclosure,
  chakra,
  Divider,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  Progress,
} from '@chakra-ui/react';
import { ShareIcon, DeleteIcon, CheckCircleOutlineIcon, ChequeIcon, CalendlyIcon } from '@instamojo/icons';
import { useParams } from 'react-router-dom';
import Rupee from '@instamojo/rupee';
import { useMutation } from 'react-query';

import { IPricing } from '../../../types';
import InstamojoLogo from '../../../assets/InstamojoLogo.svg';
import Counter from './Counter';
import { validateDiscountApi } from '../../../api';
import { useSmartPageQueryData } from '../../../hooks';
import SharePageModal from './SharePageModal';
import { getDisabledButtonProps, getParameterByName, isBrowser, isFree } from '../../../utils';
import { trackEvent } from '../../../utils/analytics';

export interface ICheckoutData {
  productName: string;
  productAmount: number;
  productQuantity: number;
  discountCode: string;
  amount: number;
  finalAmount: number;
  isDiscountValid: boolean;
  discountedTotalAmount: number;
  selectedOption: object;
}

export interface eventArray {
  is_home_page: boolean;
  scheduling_url: string;
}
export interface WidgetProps {
  events: eventArray[];
}
export interface ICalendlyData {
  widget_params: WidgetProps;
}

interface PricingProps {
  pricing: IPricing[];
  paymentButtonText: string;
  removeImBranding: boolean;
  calendlyProps?: ICalendlyData;
  paymentEnabled: boolean;
}

const Pricing: React.FC<PricingProps> = ({ pricing, paymentButtonText, removeImBranding, calendlyProps, paymentEnabled }) => {
  const { slug } = useParams();
  const calendlyEvent = calendlyProps?.widget_params?.events?.filter((item) => item.is_home_page);
  const [selectedProduct, setSelectedProduct] = useState(() => {
    return pricing.find((option) => option.is_default)?.purpose || '';
  });
  const [selectedOption, setSelectedOption] = useState(() => {
    return pricing.find((option) => option.is_default);
  });
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  const [selectedProductAmount, setSelectedProductAmount] = useState(() => {
    const option = pricing.find((option) => option.is_default);
    if (!option) {
      return null;
    }
    if (option.is_discounted_amount_allowed) {
      return Number(option.discounted_amount);
    }
    if (option.flexible_amount_allowed) {
      return null;
    } else {
      return +option.amount;
    }
  });
  const getDiscountCodeFromUrl = getParameterByName('DiscountCode')
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [discountCode, setDiscountCode] = useState(!selectedOption?.flexible_amount_allowed && !isFree(selectedOption?.amount) && getDiscountCodeFromUrl ? getDiscountCodeFromUrl : '');
  const [isDiscountError, setIsDiscountError] = useState(false);
  const [isDiscountValid, setIsDiscountValid] = useState(false);
  const [discountedTotalAmount, setDiscountedTotalAmount] = useState(null);

  const { isOpen: isShareModalOpen, onOpen: onShareModalOpen, onClose: onShareModalClose } = useDisclosure({
    id: 'sharePageModal',
  });

  const { isOpen: isCalendlyModalOpen, onOpen: onCalendlyModalOpen, onClose: onCalendlyModalClose } = useDisclosure({
    id: 'calendlyModal',
  });

  const smartPage = useSmartPageQueryData();

  const pricingOptionHeight =
    isDiscountValid && calendlyEvent?.length ? "calc(100vh - 515px)"
    : smartPage.discount_enabled && calendlyEvent?.length ? "calc(100vh - 450px)"
    : isDiscountValid ? "calc(100vh - 410px)"
    : calendlyEvent?.length ? "calc(100vh - 386px)"
    : smartPage.discount_enabled ? "calc(100vh - 350px)"
    : "calc(100vh - 286px)";

  const disabledButtonProps = getDisabledButtonProps(paymentEnabled);

  useEffect(() => {
    const checkoutData: ICheckoutData = {
      productName: selectedProduct,
      productAmount: selectedProductAmount,
      productQuantity: selectedQuantity,
      discountCode: discountCode,
      amount: selectedProductAmount * selectedQuantity,
      finalAmount: isDiscountValid ? discountedTotalAmount : selectedProductAmount * selectedQuantity,
      isDiscountValid: isDiscountValid,
      discountedTotalAmount: discountedTotalAmount,
      selectedOption: selectedOption,
    };
    if (isBrowser()) {
      try {
        window?.localStorage?.setItem('checkoutData', JSON.stringify(checkoutData));
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    selectedProduct,
    selectedProductAmount,
    selectedQuantity,
    discountCode,
    isDiscountValid,
    discountedTotalAmount,
    selectedOption,
  ]);

  const { mutate: validateDiscount } = useMutation(validateDiscountApi, {
    onSuccess: (data) => {
      setIsDiscountValid(data.is_valid);
      setIsDiscountError(!data.is_valid);
      if (data.is_valid) {
        setDiscountedTotalAmount(data.total_amount);
        trackEvent('Applied Buyer Discount Coupon', {
          amount: selectedProductAmount * selectedQuantity,
          discount_amount: data.total_amount,
          discount_code: discountCode,
          source: 'Home Page',
          is_smart_page_order: true,
        });
      }
    },
  });

  const handleDiscountCode = () => {
    if(discountCode === '') {
      setIsDiscountError(true)
    } else {
      validateDiscount({
        slug,
        code: discountCode,
        amount: selectedProductAmount * selectedQuantity,
      });
    }
  };

  useEffect(() => {
    if(getDiscountCodeFromUrl !== '' && !selectedOption?.flexible_amount_allowed && !isFree(selectedOption?.amount)) {
      handleDiscountCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductClick = (purpose) => {
    const option = pricing.find((elem) => elem.purpose === purpose);
    setSelectedOption(option);
    if (option.is_discounted_amount_allowed) {
      setSelectedProductAmount(Number(option.discounted_amount));
    } else if (option.flexible_amount_allowed) {
      setSelectedProductAmount(null);
    } else {
      setSelectedProductAmount(+option.amount);
    }
    setSelectedProduct(purpose);
    setSelectedQuantity(1);
    removeDiscount();
  };

  const removeDiscount = () => {
    setDiscountCode('');
    setIsDiscountValid(false);
  };

  const handleQuantityChange = (quantity) => {
    setSelectedQuantity(quantity);
    if (isDiscountValid) {
      validateDiscount({
        slug,
        code: discountCode,
        amount: selectedProductAmount * quantity,
      });
    }
  };

  const handlePayButtonClick = () => {
    const getQueryParams = window.location.search;
    const isQueryParamsExist = getQueryParams !== '' ? `/${getQueryParams}` : '';
    if (slug) {
      window.location.href = `/${slug}/checkout${isQueryParamsExist}`;
    } else {
      window.location.href = `/checkout${isQueryParamsExist}`;
    }
  };

  const handleBookYourSlot = () => {
    onCalendlyModalOpen();
    trackEvent('Clicked Item', {
      item_name: 'calendly_widget',
    });
  };

  const subtotalAmount = selectedProductAmount * selectedQuantity;
  const totalAmount = isDiscountValid ? discountedTotalAmount : subtotalAmount;
  const { enabled, current_value, value, exceed, type } = selectedOption?.goal_tracker || {};
  const btnDisabled = enabled && current_value >= value && !exceed;

  let allowPaymentAccordingGoal = false;
  if (enabled && !exceed) {
    if (type === 1) {
      allowPaymentAccordingGoal = selectedProductAmount > value - current_value;
    }
  }

  return (
    <Box mb={{ base: 0, md: '24px' }} className="sidebar">
      <Box
        border={{ base: 'none', md: '1px solid #E7E7E9' }}
        borderRadius="4px"
        ml={{ md: '16px' }}
        mr={{ md: '16px' }}
        w={{ base: '100%', md: '340px' }}
        mt={{ base: 0, md: '20px' }}
        bgColor="white"
      >
        <Box
         px={{ base: '16px', md: '32px' }}
         pt={{ base: 0, md: '32px' }}
         w={{ base: '100%', md: '346px' }}
         maxHeight={pricingOptionHeight}
         overflowY="auto"
         overflowX="hidden"
        >
          <Text fontSize="14" fontWeight="400" mb="0" color="#8F8FA1">
            Please choose an option below to pay
          </Text>
          <RadioGroup value={selectedProduct} onChange={handleProductClick}>
            <VStack spacing={4} alignItems="flex-start" divider={<StackDivider borderColor="gray.200" />} py="4">
              {pricing
                .sort((x, y) => (x.priority < y.priority ? -1 : 0))
                .map((item) => {
                  const { value: goalTrackerValue, current_value, enabled, type, exceed } = item?.goal_tracker || {};
                  const currentValue = current_value || 0;
                  const isTypeOne = type === 1;
                  const isTypetwo = type === 2;
                  const checkValueType = isTypetwo && currentValue === 0;
                  const getGoalPercentColor = goalTrackerValue - currentValue <= Math.trunc(goalTrackerValue / 10);
                  const goalPercentage = enabled && (100 * currentValue) / goalTrackerValue;
                  const goalPercentageCheck = enabled && goalPercentage >= 100 ? 100 : goalPercentage;
                  const goalItemValue = Math.trunc(goalTrackerValue);
                  const showCounter = item.multiple_quantities_allowed && selectedProduct === item.purpose;
                  const isGoalExceed = currentValue >= goalTrackerValue && !exceed;
                  let goalIText;
                  let colorGoalIText;

                  if (isTypetwo) {
                    colorGoalIText = getGoalPercentColor && goalTrackerValue > currentValue ? '#DF542F' : '#4E4E91';
                  } else {
                    colorGoalIText = '#4E4E91';
                  }

                  if (enabled) {
                    if (currentValue < goalTrackerValue) {
                      goalIText =
                        currentValue === 0 ? (
                          'Be the first one to purchase!'
                        ) : isTypeOne ? (
                          <Text mb="0" fontSize="13px">
                            <strong>
                              <Rupee value={currentValue} />
                            </strong>{' '}
                            achieved of <Rupee value={goalTrackerValue} />
                          </Text>
                        ) : (
                          <Text mb="0" fontSize="13px">
                            <strong>{goalTrackerValue - currentValue}</strong>/{goalItemValue} remaining
                          </Text>
                        );
                    } else if (currentValue >= goalTrackerValue) {
                      goalIText = isTypeOne ? (
                        <Text mb="0" fontSize="13px">
                          <strong>
                            <Rupee value={currentValue} />
                          </strong>{' '}
                          achieved of <Rupee value={goalTrackerValue} />
                        </Text>
                      ) : (
                        <Text mb="0" fontSize="13px">
                          <strong>{currentValue}</strong>/{goalItemValue} completed
                        </Text>
                      );
                    }
                  }

                  return (
                    <>
                      <Box d="flex" key={item.purpose} alignItems="flex-start" justifyContent="space-between" w="100%">
                        <Box d="flex" flex="1">
                          <Radio
                            mr="2"
                            value={item.purpose}
                            alignItems="baseline"
                            isDisabled={!item.is_active || (enabled && isGoalExceed)}
                          >
                            <Text mb="1" fontSize="14" fontWeight="500" color="#444463">
                              {item.purpose}
                            </Text>
                            <Text fontSize="14" mb="0" color="#8F8FA1">
                              {item.is_discounted_amount_allowed && (
                                <chakra.span mr="8px">
                                  <Rupee value={item.discounted_amount} />
                                </chakra.span>
                              )}
                              <chakra.span
                                textDecoration={item.is_discounted_amount_allowed ? 'line-through' : 'none'}
                                fontSize={item.is_discounted_amount_allowed ? '12' : '14'}
                              >
                                {!item.flexible_amount_allowed && !isFree(item.amount) ? (
                                  <Rupee value={item.amount} />
                                ) : null}
                              </chakra.span>
                            </Text>
                          </Radio>
                        </Box>
                        {(!item.is_active || (enabled && isGoalExceed)) && (
                          <Text fontSize="14" fontStyle="italic" color="#8F8FA1">
                            Sold Out
                          </Text>
                        )}
                        {showCounter && item.is_active && !enabled && (
                          <Counter
                            onQuantityChange={handleQuantityChange}
                            item={item}
                            flexibleAmount={selectedProductAmount}
                          />
                        )}
                        {showCounter && enabled && ((currentValue <= goalTrackerValue && exceed) || !isGoalExceed) && (
                          <Counter
                            onQuantityChange={handleQuantityChange}
                            item={item}
                            flexibleAmount={selectedProductAmount}
                          />
                        )}
                      </Box>
                      {enabled && (
                        <Box
                          ml="28px"
                          border="1px solid #EEEEF4"
                          p="16px 12px 10px"
                          w="calc(100% - 20px)"
                          borderRadius="4"
                          opacity={item.is_active ? '1' : '0.4'}
                        >
                          <Progress
                            value={goalPercentageCheck}
                            borderRadius="4"
                            bg="#EEEEF4"
                            size="sm"
                            sx={{
                              '& > div': {
                                background: 'linear-gradient(90deg, #73D478 0%, #59A35D 100%)',
                              },
                            }}
                          />
                          <Box
                            display="flex"
                            flexDirection={checkValueType ? 'column' : 'row'}
                            mt="8px"
                            color={colorGoalIText}
                            alignItems="start"
                          >  
                            <Box
                              display={checkValueType ? 'flex' : 'inherit'}
                              flexDirection={checkValueType ? 'row' : 'column'}
                            >
                              <ChequeIcon size="24px" />
                              {checkValueType ? (
                                <Text mb="0" fontSize="13px">
                                  <strong>{goalTrackerValue - currentValue}</strong>/{goalItemValue} remaining
                                </Text>
                              ) : null}
                            </Box>
                            <Text mb="0" fontSize="13px" mt="-2px" d="flex">
                              {goalIText}
                              {type === 2 && getGoalPercentColor && goalTrackerValue > currentValue ? ', hurry!' : ''}
                            </Text>
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                })}
            </VStack>
          </RadioGroup>
        </Box>
        <Box
        px={{ base: '16px', md: '32px' }}
        pt={{ base: 0, md: '8px' }}
        >
          {selectedOption?.flexible_amount_allowed ? (
            <FormControl width="80%" mx="7" color="#444463" isInvalid={isDiscountError} mb="26px">
              <Input
                type="number"
                borderColor="#E7E7E9"
                value={selectedProductAmount || null}
                onChange={(e) => setSelectedProductAmount(+e.target.value)}
                sx={{
                  _focus: null,
                  _active: null,
                  _hover: null,
                }}
              />
              <FormErrorMessage>Invalid Discount Code</FormErrorMessage>
              <Text fontSize="14" fontWeight="400" my="1" color="#8F8FA1">
                Please enter amount (min <Rupee value={selectedOption.min_amount} />)
              </Text>
            </FormControl>
          ) : null}

          {smartPage.discount_enabled && !selectedOption?.flexible_amount_allowed && !isFree(selectedOption?.amount) && (
            <>
              {!isDiscountValid ? (
                <FormControl color="#444463" isInvalid={isDiscountError}>
                  <FormLabel fontSize="13" color="#8F8FA1">
                    Discount Code
                  </FormLabel>
                  <InputGroup borderColor="#E7E7E9">
                    <Input
                      pr="65px"
                      type="text"
                      value={discountCode}
                      onChange={(e) => {
                        setDiscountCode(e.target.value)
                        setIsDiscountError(false)
                      }}
                      placeholder="Apply code here"
                      fontSize="14px"
                      sx={{
                        _focus: null,
                        _active: null,
                        _hover: null,
                      }}
                    />
                    <InputRightElement width="60px">
                      <Button
                        variant="outline"
                        onClick={handleDiscountCode}
                        textTransform="uppercase"
                        fontSize="14"
                        lineHeight="inherit"
                      >
                        Apply
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{discountCode === '' ? 'Please Enter Discount Code' : 'Invalid Discount Code'}</FormErrorMessage>
                </FormControl>
              ) : (
                <HStack p="4" border="1px dashed" borderColor="#ccc">
                  <Text color="green" d="flex" m="0" wordBreak="break-word">
                    <Text as="span">
                      <CheckCircleOutlineIcon />
                    </Text>
                    {discountCode} applied
                  </Text>
                  <IconButton
                    variant="ghost"
                    color="red"
                    aria-label="delete discount"
                    icon={<DeleteIcon />}
                    onClick={removeDiscount}
                  />
                </HStack>
              )}
            </>
          )}

          <Box my="6">
            {isDiscountValid && (
              <>
                <Box d="flex" justifyContent="space-between" color="#444463" mb="4px">
                  <Text m="0">Sub Total</Text>
                  <Rupee value={subtotalAmount} retainDecimalPlaces />
                </Box>
                <Box d="flex" justifyContent="space-between" color="#444463" mb="4px">
                  <Text m="0">Promo Discount</Text>
                  <Rupee insertSpaceAfterMinus value={(subtotalAmount - totalAmount) * -1} retainDecimalPlaces />
                </Box>
                <Divider mb="4px" />
              </>
            )}

            {!isFree(selectedOption?.amount) ? (
              <Box d="flex" justifyContent="space-between" color="#444463">
                <Text m="0">Amount</Text>
                <Rupee value={totalAmount} retainDecimalPlaces />
              </Box>
            ) : null}
          </Box> 

          <Button
            {...disabledButtonProps}
            width="100%"
            mb="3"
            h="54px"
            fontSize="18px"
            onClick={handlePayButtonClick}
            isDisabled={
              !paymentEnabled ||
              (selectedOption?.flexible_amount_allowed && (selectedOption?.min_amount || 0) > selectedProductAmount) ||
              !selectedOption.is_active ||
              btnDisabled ||
              allowPaymentAccordingGoal
            }
          >
            {paymentButtonText}
          </Button>
          {!removeImBranding && (
            <Box d="flex" justifyContent="center">
              <Text fontSize="11" color="#8F8FA1">
                Powered by{' '}
              </Text>
              <Image src={InstamojoLogo} alt="Instamojo" mb="2" height="16px" ml="1" />
            </Box>
          )}
        </Box>
      </Box>
      {calendlyEvent?.length ? (
        <>
          <Box
            p={{ base: '8px 16px', md: '8px' }}
            border={{ base: 'none', md: '1px solid #E1FEFF' }}
            borderRadius="4px"
            mx={{ md: '16px' }}
            w={{ base: '100%', md: '340px' }}
            mt="0"
            bgColor="#E1FEFF"
          >
            <Stack direction="row" justifyContent="center">
              <Button leftIcon={<CalendlyIcon />} variant="ghost" onClick={handleBookYourSlot}>
                Book your slot now
              </Button>
            </Stack>
            <Modal isOpen={isCalendlyModalOpen} onClose={onCalendlyModalClose}>
              <ModalOverlay />
              <ModalContent maxW={isLargerThan600 ? '648px' : '100%'} h="636px" position="relative">
                <ModalCloseButton
                  color="white"
                  fontSize="20px"
                  zIndex="9999"
                  position="absolute"
                  right="0"
                  top="0"
                  mt="-40px"
                  mr={{ base: 0, md: '-40px' }}
                  outline="none"
                  _focus={{ outline: 'none' }}
                  _hover={{ bg: 'transparent' }}
                  onClick={() =>
                    trackEvent('Clicked Item', {
                      item_name: 'closed_calendly_modal',
                    })
                  }
                />
                <ModalBody p="0">
                  <iframe
                    src={calendlyEvent[0]?.scheduling_url}
                    width="100%"
                    height="100%"
                    title="calendlyEventModal"
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
          <Box m={{ md: '16px' }} w={{ base: '100%', md: '340px' }} display={{ base: 'none', md: 'block' }}>
            <Text color="#8F8FA1" fontSize="12px">
              If you do not like the Program within first 90 Minutes, your amount would be refunded in the next 24
              Hours.
            </Text>
          </Box>
        </>
      ) : null}
      <Box maxW="340px" ml="4" mt="4" d={{ base: 'none', md: 'block' }}>
        <Button
          width="100%"
          mb="2"
          variant="solid"
          bgColor="#fff"
          color="primary"
          fontSize="14px"
          onClick={onShareModalOpen}
        >
          <ShareIcon size="14px" />
          <Box as="span" ml="2">
            Share this page
          </Box>
        </Button>
        <SharePageModal isOpen={isShareModalOpen} onClose={onShareModalClose} />
      </Box>
    </Box>
  );
};

export default Pricing;
