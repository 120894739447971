const Accordion = {
  parts: ['container', 'button', 'panel', 'icon'],
  baseStyle: {
    container: {
      _first: {
        borderTopWidth: 0,
      },
    },
    button: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
};

export default Accordion;
