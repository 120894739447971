import './theme/fonts/Bitter/bitter.css';
import './theme/fonts/Inter/inter.css';
import './theme/fonts/NotoSans/noto-sans.css';
import './theme/fonts/RobotoSlab/roboto-slab.css';

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { Hydrate } from 'react-query/hydration';

import Routes from './Routes';
import { isBrowser } from './utils';

declare global {
  interface Window {
    __REACT_QUERY_STATE__: any;
  }
}

let dehydratedState = {};
if (isBrowser()) {
  dehydratedState = window.__REACT_QUERY_STATE__;
  if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
    axios.defaults.headers.common['x-im-username'] = process.env.REACT_APP_USERNAME;
    axios.defaults.headers.common['x-im-pagename'] = window.location.pathname.split('/')[1];
  } else {
    // TODO: create utility functions for these, handle empty username
    var username = window.__REACT_QUERY_STATE__?.queries[0]?.state?.data?.username || '';
    var page_name = window.__REACT_QUERY_STATE__?.queries[0]?.state?.data?.page_name || '';

    if (window.location.hostname.split('.').slice(-2).join('.') === 'mojo.page') {
      username = window.location.hostname.split('.')[0].split('-').join('_');
      page_name = window.location.pathname.split('/')[1];
    }

    const domainUsername = username.split('_').join('-');  // normalized
    const baseURL = `https://${domainUsername}.${window.context.SMART_PAGES_BASE_DOMAIN}`;

    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.common['x-im-username'] = username;
    axios.defaults.headers.common['x-im-pagename'] = page_name;
  }
}

if (isBrowser()) {
  setLogger(window?.console);
} else {
  setLogger({
    log: () => {},
    warn: () => {},
    error: () => {},
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

ReactDOM.hydrate(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <App />
      </Hydrate>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
