import { useEffect } from 'react';
import $ from 'jquery';

interface ScriptObject {
  slug: string;
  content: Object;
  section: string;
  enabled: boolean;
}

interface InjectScriptProps {
  widgets: Record<string, ScriptObject>;
  page: number;
  section?: string;
}

const InjectScript: React.FC<InjectScriptProps> = ({ widgets = {}, page }) => {
  const headerScriptContent = Object.values(widgets)
    .map((widget) => {
      if (widget.enabled === true && widget.section === 'HEADER' && page in widget.content) return widget.content[page];
      else return '';
    })
    .join('\n');

  const footerScriptContent = Object.values(widgets)
    .map((widget) => {
      if (widget.enabled === true && widget.section === 'FOOTER' && page in widget.content) return widget.content[page];
      else return '';
    })
    .join('\n');

  useEffect(() => {
    let fragment = document.createElement('template');

    // The reason we are using jquery becuase, document.head.appendChild was adding
    // script to head but browser was not executing after that.
    if (headerScriptContent) {
      fragment.innerHTML = headerScriptContent;
      $('head').append(fragment.content);
    }
    if (footerScriptContent) {
      fragment.innerHTML = footerScriptContent;
      $('body').append(fragment.content);
    }
  }, [headerScriptContent, footerScriptContent]);

  return null;
};

export default InjectScript;
