import { createBreakpoints } from '@chakra-ui/theme-tools';

import Button from './components/button';
// import Heading from "./components/heading";
import Input from './components/input';
// import Text from "./components/text";
import Accordion from './components/accordion';
import Radio from './components/radio';

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
});

const overrides = {
  components: {
    Accordion,
    Input,
    Radio,
    Button,
  },
  breakpoints,
};

export default overrides;
