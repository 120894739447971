import { Center, Spinner } from '@chakra-ui/react';

const PageLoader = () => {
  return (
    <Center h="100vh" color="primary">
      <Spinner w="16px" h="16px" />
    </Center>
  );
};

export default PageLoader;
