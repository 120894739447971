import { Avatar, Box, Divider, HStack, Text, Image, Link, useColorModeValue, chakra } from '@chakra-ui/react';
import InstagramLogo from '../../assets/instagram.png';
import { FacebookIcon, TwitterIcon, YoutubeV2Icon } from '@instamojo/icons';
import InstagramLightLogo from '../../assets/instagramLight.png';
import { trackEvent } from '../../utils/analytics';

interface ProfileProps {
  onOpen: () => void;
  profileInfo: {
    name: string;
    profile: string;
    facebook_link: string;
    twitter_link: string;
    instagram_link: string;
    photo_url: string;
    youtube_link: string;
  };
  setIsTos: (isTos: boolean) => void;
  tosPolicies: object;
}

const Profile: React.FC<ProfileProps> = ({ onOpen, profileInfo, setIsTos, tosPolicies }) => {
  const instagramLogo = useColorModeValue(InstagramLogo, InstagramLightLogo);
  const theme = useColorModeValue('light', 'dark');
  const profileTextColor = useColorModeValue('#8F8FA1', 'rgba(255, 255, 255, 0.6)');

  const iconProps = theme === 'dark' ? { color: '#fff' } : {};

  const { name, profile, facebook_link, twitter_link, instagram_link, photo_url, youtube_link } = profileInfo || {};
  const handleClick = (isTosSelected: boolean) => {
    onOpen();
    setIsTos(isTosSelected);
    trackEvent('Viewed Policy', {
      policy_name: isTosSelected ? 'Terms Of Service Policy' : 'Refund Policy',
      offer_creation_type: 'Smartpage',
      is_mojocommerce_order: false,
    });
  };
  return (
    <>
      <Box p="16px" borderRadius="4px" mb="10">
        {photo_url && <Avatar bg="transparent" name={name} src={photo_url} mb="1" />}
        <Text fontSize="18" mb="4px" fontWeight="500" mt="8px">
          {name}
        </Text>
        <Text fontSize="16" color={profileTextColor}>
          <chakra.pre fontFamily="Noto Sans" whiteSpace="pre-wrap">
            {profile}
          </chakra.pre>
        </Text>
        {facebook_link || twitter_link || instagram_link || youtube_link ? <Divider my="3" /> : null}
        <HStack spacing="4">
          {facebook_link && (
            <Link href={facebook_link} target="_blank">
              <FacebookIcon {...iconProps} />
            </Link>
          )}
          {twitter_link && (
            <Link href={twitter_link} target="_blank">
              <TwitterIcon {...iconProps} />
            </Link>
          )}
          {instagram_link && (
            <Link href={instagram_link} target="_blank">
              <Image src={instagramLogo} alt="FB logo" boxSize="5" />
            </Link>
          )}
          {youtube_link && (
            <Link href={youtube_link} target="_blank">
              <YoutubeV2Icon color={theme === 'dark' ? '#fff' : '#ff0002'} />
            </Link>
          )}
        </HStack>
      </Box>
      {Object.keys(tosPolicies).length > 0 ? (
        <Box fontSize="14px" color="#4E4E91">
          <Link
            onClick={() => {
              handleClick(true);
            }}
          >
            Terms of Services
          </Link>
          <chakra.span padding="2px">&bull;</chakra.span>
          <Link
            onClick={() => {
              handleClick(false);
            }}
          >
            Refund Policy
          </Link>
        </Box>
      ) : null}
      <Divider borderColor="#DF542F" m="8px 0 32px 0" />
    </>
  );
};

export default Profile;
