import { useEffect } from 'react';
import $ from 'jquery';
import InnerHTML from 'dangerously-set-html-content'

interface ScriptObject {
  slug?: string;
  content: Object;
  section: string;
  enabled: boolean;
  page_type: Array<number>
}

interface InjectCustomScriptProps {
  widgets: Array<ScriptObject>;
  page: number;
  section?: string;
  isFooter?: boolean;
}

const InjectCustomScript: React.FC<InjectCustomScriptProps> = ({ widgets = [], page, isFooter = false }) => {
  const headerScriptContent = Object.values(widgets)
    .map((widget) => {
      const {enabled, section, page_type, content} = widget
      if (enabled === true && section === 'HEADER' && page_type.includes(page)) return content;
      else return '';
    })
    .join('\n');

  useEffect(() => {
    let fragment = document.createElement('template');

    // The reason we are using jquery because, document.head.appendChild was adding
    // script to head but browser was not executing after that.
    if (headerScriptContent && !isFooter) {
      fragment.textContent = headerScriptContent;
      $('head').append(fragment.textContent);
    }
  }, [headerScriptContent, isFooter]);

  const footerContent = Object.values(widgets)
    .map((widget) => {
      const {enabled, section, page_type, content} = widget
      if (enabled === true && section === 'FOOTER' && page_type.includes(page)) return content;
      else return '';
    })
    .join('\n')
    return(
      (isFooter && footerContent) ? <InnerHTML html={footerContent} /> : <></>
    )

};

export default InjectCustomScript;
