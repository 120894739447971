import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  withDefaultColorScheme,
  ColorMode,
  theme as baseTheme,
} from '@chakra-ui/react';
import React from 'react';

import customTheme from '.';

interface ThemeProviderProps {
  children: React.ReactNode;
  themeMode?: ColorMode;
  dayColor?: string;
  nightColor?: string;
  fontFamily?: string;
  pageName?: string;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, themeMode, dayColor, nightColor, fontFamily, pageName }) => {
  const primaryColor = themeMode === 'light' ? dayColor : nightColor;
  const theme = extendTheme(
    {
      ...customTheme,
      config: {
        initialColorMode: pageName === 'checkout' ? 'light' : themeMode || 'light',
        useSystemColorMode: false,
      },
      fonts: {
        heading: fontFamily,
        body: fontFamily,
      },
      colors: {
        ...baseTheme.colors,
        primary: primaryColor || '#4E4E91',
      },
      styles: {
        global: {
          'html, body': {
            fontFamily: fontFamily,
            backgroundColor: pageName === 'checkout' ? '#fff' : themeMode === 'dark' ? '#333333' : '#fff',
          },
          p: {
            fontSize: '16px',
            marginBottom: '16px',
          },
          h1: {
            fontSize: '26px',
          },
          h2: {
            fontSize: '19px',
          },
          a: {
            color: primaryColor,
            textDecoration: 'underline',
          },
        },
      },
    },
    withDefaultColorScheme({ colorScheme: 'primary' }),
  );

  return (
    <div>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        {children}
      </ChakraProvider>
    </div>
  );
};

export default ThemeProvider;
