import { Box, Center, Modal, ModalContent, ModalOverlay, Spinner, useMediaQuery, Text } from '@chakra-ui/react';
import React from 'react';

interface NewCheckoutPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentLink: string;
  isPaymentFrameLoading: boolean;
  handlePaymentIframeLoad: () => void;
}

const NewCheckoutPaymentModal: React.FC<NewCheckoutPaymentModalProps> = ({
  paymentLink,
  isOpen,
  onClose,
  isPaymentFrameLoading,
  handlePaymentIframeLoad,
}) => {
  const newUrl = new URL(paymentLink);
  const newPaymentLink = `${window.context.NEW_PAYMENT_BASE_URL}/payment${newUrl.pathname}`;
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={!isLargerThan600 ? 'full' : 'sm'}
    >
      <ModalOverlay />
      <ModalContent width={isLargerThan600 ? '414px' : '100%'}>
        <Box position="relative">
          {isPaymentFrameLoading && (
            <Center
              position="absolute"
              top="0"
              right="0"
              left="0"
              bottom="0"
              zIndex="10"
              bg="white"
              d="flex"
              flexDirection="column"
            >
              <Spinner size="sm" />
              <Box textAlign="center" mt="24px">
                <Text fontSize="20" mb="8px">
                  Please wait
                </Text>
                <Text fontSize="16" mb="24px">
                  We are processing your order.
                </Text>
                <Text color="red" fontSize="10">
                  Do not press back or refresh this page
                </Text>
              </Box>
            </Center>
          )}
          <Box overflow="hidden">
            <iframe
              src={`${newPaymentLink}`}
              title="paymentModal"
              width="100%"
              style={{
                height: !isLargerThan600 ? '100dvh' : 'calc(100vh - 60px)',
                maxHeight: '-webkit-fill-available',
                marginTop: '0px',
                position: 'relative',
                left: '0',
              }}
              onLoad={handlePaymentIframeLoad}
            />
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default NewCheckoutPaymentModal;
