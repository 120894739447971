import React, { useEffect, useState } from 'react';
import {
  Box,
  chakra,
  Heading,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Rupee from '@instamojo/rupee';
import { isBrowser, isFree } from '../../utils';
import { useMutation } from 'react-query';
import { validateDiscountApi } from '../../api';
import { DeleteIcon, CheckCircleOutlineIcon } from '@instamojo/icons';
import { ICheckoutData } from '../Home/pricing/Pricing';
import { useSmartPageQueryData } from '../../hooks';
import { trackEvent } from '../../utils/analytics';

const OrderSummary = ({ orderId, finalDiscountCode }) => {
  const { slug } = useParams();
  let checkoutData = null;
  if (isBrowser()) {
    checkoutData = orderId
      ? JSON.parse(window?.localStorage?.getItem('abandonedPaymentCheckoutData'))
      : JSON.parse(window?.localStorage?.getItem('checkoutData'));
  }

  const [discountCode, setDiscountCode] = useState(checkoutData?.discountCode);
  const [isDiscountError, setIsDiscountError] = useState(false);
  const [isDiscountValid, setIsDiscountValid] = useState(checkoutData?.isDiscountValid);
  const [discountedTotalAmount, setDiscountedTotalAmount] = useState(checkoutData?.discountedTotalAmount);
  const finalAmount = isDiscountValid ? discountedTotalAmount : checkoutData?.amount;
  const selectedProduct = checkoutData?.productName;
  const selectedProductAmount = checkoutData?.productAmount;
  const selectedQuantity = checkoutData?.productQuantity;
  const selectedOption = checkoutData?.selectedOption;
  const smartPage = useSmartPageQueryData();

  useEffect(() => {
    const checkoutData: ICheckoutData = {
      productName: selectedProduct,
      productAmount: selectedProductAmount,
      productQuantity: selectedQuantity,
      discountCode: isDiscountValid ? discountCode : '',
      amount: selectedProductAmount * selectedQuantity,
      finalAmount: finalAmount,
      isDiscountValid: isDiscountValid,
      discountedTotalAmount: discountedTotalAmount,
      selectedOption: selectedOption,
    };
    if (isBrowser()) {
      if (!orderId) {
        window?.localStorage?.setItem('checkoutData', JSON.stringify(checkoutData));
      } else {
        window.localStorage.setItem('abandonedPaymentCheckoutData', JSON.stringify(checkoutData));
      }
    }
  }, [
    selectedProduct,
    selectedProductAmount,
    selectedQuantity,
    discountCode,
    finalAmount,
    isDiscountValid,
    discountedTotalAmount,
    selectedOption,
    orderId,
  ]);

  const { mutate: validateDiscount } = useMutation(validateDiscountApi, {
    onSuccess: (data) => {
      setIsDiscountValid(data.is_valid);
      setIsDiscountError(!data.is_valid);
      if (data.is_valid) {
        setDiscountedTotalAmount(data.total_amount);
        trackEvent('Applied Buyer Discount Coupon', {
          amount: finalAmount,
          discount_amount: discountedTotalAmount,
          discount_code: discountCode,
          source: 'Checkout Page',
          is_smart_page_order: true,
        });
      }
    },
  });

  const handleDiscountCode = () => {
    if(discountCode === '') {
      setIsDiscountError(true)
    } else {
      validateDiscount({
        slug,
        code: discountCode,
        amount: checkoutData?.amount,
      });
    }
  };

  const removeDiscount = () => {
    setDiscountCode('');
    setIsDiscountValid(false);
  };

  return (
    <Box border="1px solid" borderColor="gray.200" p="16px" borderRadius="md" mb="24px">
      <Heading fontSize="14" mb="16px" fontWeight="400">
        Order Summary for <chakra.span fontWeight="600">{checkoutData?.productName}</chakra.span>
      </Heading>
      <Box fontSize="13" d="flex" justifyContent="space-between">
        <chakra.span>
          Subtotal ({checkoutData?.productQuantity} item{checkoutData?.productQuantity > 1 ? 's' : ''})
        </chakra.span>
        <Rupee value={checkoutData?.amount} retainDecimalPlaces />
      </Box>
      {(smartPage.discount_enabled || finalDiscountCode) &&
        !selectedOption?.flexible_amount_allowed &&
        !isFree(finalAmount) && (
          <>
          {!isDiscountValid ? (
            <>
              <Divider my="16px" />
              <FormControl color="#444463" isInvalid={isDiscountError}>
                <InputGroup borderColor="#E7E7E9">
                  <Input
                    pr="60px"
                    type="text"
                    fontSize="13"
                    placeholder="Enter Discount Code"
                    value={discountCode}
                    onChange={(e) => {
                      setDiscountCode(e.target.value)
                      setIsDiscountError(false)
                    }}
                  />
                  <InputRightElement width="60px">
                    <Button
                      variant="unstyled"
                      onClick={handleDiscountCode}
                      textTransform="uppercase"
                      fontSize="13"
                      lineHeight="inherit"
                      sx={{
                        _focus: null,
                        _active: null,
                        _hover: null,
                      }}
                    >
                      Apply
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage fontSize="13">{discountCode === '' ? 'Please Enter Discount Code' : 'Invalid Discount Code'}</FormErrorMessage>
              </FormControl>
            </>
          ) : (
            <>
              <Box fontSize="13" d="flex" justifyContent="space-between" mt="4px">
                <chakra.span>Promo Discount</chakra.span>
                <Rupee value={discountedTotalAmount - checkoutData?.amount} retainDecimalPlaces insertSpaceAfterMinus />
              </Box>
              <Divider my="16px" />
              <HStack alignContent="center" px="4" border="1px dashed" borderColor="#ccc">
                <Text fontSize="13" color="green" d="flex" m="0" wordBreak="break-word">
                  <Text as="span">
                    <CheckCircleOutlineIcon size="20" />
                  </Text>
                  {discountCode} applied
                </Text>
                <IconButton
                  variant="ghost"
                  color="red"
                  aria-label="delete discount"
                  icon={<DeleteIcon size="20" />}
                  onClick={removeDiscount}
                />
              </HStack>
            </>
          )}
        </>
      )}
      <Divider my="16px" />
      <Box d="flex" justifyContent="space-between" fontSize="16" fontWeight="bold">
        <chakra.span>Order Total</chakra.span>
        <Rupee value={finalAmount} retainDecimalPlaces insertSpaceAfterMinus />
      </Box>
    </Box>
  );
};

export default OrderSummary;
