import {
  Box,
  Divider,
  Heading,
  useMediaQuery,
  Image,
  AspectRatio,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  IconButton,
  DrawerContent,
  Link,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CloseCircleIcon } from '@instamojo/icons';

import Pricing from './pricing/Pricing';
import Testimonials from './Testimonials';
import Faqs from './Faqs';
import Profile from './Profile';
import Footer from './Footer';
import ThemeProvider from '../../theme/ThemeProvider';
import PageLoader from '../../components/PageLoader';
import PageNotFound from '../../components/PageNotFound';
import { IsPreviewPage, useSmartPage } from '../../hooks';
import Pattern from '../../assets/pattern.png';
import { trackEvent } from '../../utils/analytics';
import { useMutation } from 'react-query';
import { getReportAbuseFlagApi, getPolicyApi } from '../../api';
import { isBrowser } from '../../utils';
import InjectScript from '../../components/InjectScript';
import EnquiryForm from './EnquiryForm';
import PolicyModal from './PolicyModal';
import InjectCustomScript from '../../components/InjectCustomScript';
import { convertDate, convert24To12 } from '../../utils';
import './index.css';

const COVER_TYPES = {
  IMAGE: 1,
  VIDEO: 2,
};

const getYoutubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

let isFetched = false;
const Home = () => {
  let sessions: any[];
  let location: string;
  let dataWithModifiedDateAndLocation: string;
  const [isLargerThan720] = useMediaQuery('(min-width: 720px)');
  const [isLargerThan1080] = useMediaQuery('(min-width: 1080px)');

  const {
    isOpen: isPricingDialogOpen,
    onOpen: onPricingDialogOpen,
    onClose: onPricingDialogClose,
  } = useDisclosure({ id: 'pricingDialog' });
  const {
    isOpen: isPolicyModalOpen,
    onOpen: onPolicyModalOpen,
    onClose: onPolicyModalClose,
  } = useDisclosure({ id: 'PolicyModal' });
  const [showReportAbuse, setShowReportAbuse] = useState(false);
  const [policy, setPolicy] = useState({});
  const [isTos, setIsTos] = useState(true);
  const { data, isError, isLoading } = useSmartPage();
  const isPreview = IsPreviewPage();
  let reportAbuseLink = '';

  if (isBrowser()) {
    reportAbuseLink = `${window?.context?.INSTAMOJO_BASE_URL}/report-abuse?seller=${data?.username}&productType=3&productLink=${window.location.href}`;
  }

  const themeMode = data?.theme_mode === 2 ? 'dark' : 'light';
  const { mutate: handleGetReportAbuseDetails } = useMutation(getReportAbuseFlagApi, {
    onSuccess: (data: any) => setShowReportAbuse(data.show_report_link),
  });
  const { mutate: handleGetPolicyDetails } = useMutation(getPolicyApi, {
    onSuccess: (data: any) => setPolicy(data),
  });

  const imgAltTagText = data?.title.split('"').join('&#34;');
  // Consider the payment enabled as True if payment_enabled flag is missing
  const paymentEnabled = data?.payment_enabled ?? true;

  useEffect(() => {
    if (data && !isFetched) {
      isFetched = true;
      trackEvent('Viewed Offer Page', {
        link_source: 'smart_page',
        offer_id: data.slug,
        seller_username: data?.username,
        seller_account_id: data?.account_id,
        self_flag: isPreview,
      });
      handleGetReportAbuseDetails({ username: data?.username });
      handleGetPolicyDetails({ username: data?.username });
    }
  }, [data, isPreview, handleGetReportAbuseDetails, handleGetPolicyDetails]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError || !data) {
    return <PageNotFound />;
  }

  const renderPricing = () => (
    <Pricing
      pricing={data?.pricing}
      paymentButtonText={data?.payment_button_text}
      removeImBranding={data?.remove_im_branding}
      calendlyProps={data?.page_widgets?.calendly}
      paymentEnabled={paymentEnabled}
    />
  );
  const dayColor = data?.custom_theme_enabled ? data?.custom_brand_colour : data?.day_color;

  const renderEventDate = (eventDate: string) => `Date: <span class='event'>${convertDate(eventDate)}</span>`;
  const renderEventTime = (startTime: any, endTime: any) =>
    `Time: <span class='event'>${convert24To12(startTime)} - ${convert24To12(endTime)} IST</span>`;

  if (data?.event?.sessions?.length) {
    sessions =
      data?.event?.sessions.length !== 1
        ? data?.event?.sessions.map((session: { day: string; start_time: string; end_time: string }, ind: number) => {
            return `${ind > 0 ? '<br>' : ''} <b>Session ${ind + 1}</b> ${renderEventDate(
              session.day,
            )} ${renderEventTime(session.start_time, session.end_time)}`;
          })
        : `Date: <span class='event'>${convertDate(
            data?.event?.sessions[0].day,
          )}</span> <br> Time: <span class='event'>${convert24To12(data?.event?.sessions[0].start_time)}</span>`;
  }

  if (data?.event?.event_type === 2 && data?.event?.location) {
    location = `Location: <span class='event'>${data?.event?.location}</span>`;
  }

  const dateAndLocation = `${sessions ?? ''}<br>${location ?? ''}`;

  dataWithModifiedDateAndLocation = data?.content.replace(
    '{dateAndLocation}',
    sessions || location ? dateAndLocation : '',
  );

  const customScriptList =
    data?.custom_scripts &&
    data?.custom_scripts.filter(
      (item: { page_type: number[]; section: string; enabled: any }) =>
        item.page_type.includes(1) && item.section === 'FOOTER' && item.enabled,
    );
  return (
    <ThemeProvider themeMode={themeMode} dayColor={dayColor} nightColor={data?.night_color} fontFamily={data?.font}>
      <InjectScript widgets={data.page_widgets} page={1} />
      <InjectCustomScript widgets={data?.custom_scripts} page={1} />
      <Box
        maxW={{ base: '100%', md: '932px' }}
        mt={{ base: '20px', md: '40px' }}
        mb={{ base: '100px', md: '40px' }}
        mx="auto"
        px="16px"
      >
        <Box mr={{ base: '0', md: '340px' }} w={{ base: '100%', md: 'calc(100% - 380px)' }}>
          <Heading as="h1" mb="48px" color="primary">
            {data?.title}
          </Heading>
          {data?.cover_url && (
            <Box mb="24px">
              {data?.cover_type === COVER_TYPES.IMAGE ? (
                <Image
                  src={data?.cover_url}
                  alt={imgAltTagText}
                  width="100%"
                  objectFit="cover"
                  maxH="300px"
                  fallback={<div></div>}
                />
              ) : (
                <AspectRatio>
                  <iframe
                    title={data?.title}
                    src={`https://www.youtube.com/embed/${getYoutubeId(data?.cover_url)}`}
                    allowFullScreen
                  />
                </AspectRatio>
              )}
            </Box>
          )}
          <Box>
            <Box
              sx={{
                ul: {
                  marginLeft: '24px',
                },
                ol: {
                  marginLeft: '24px',
                },
                p: {
                  marginBottom: '8px',
                },
              }}
              dangerouslySetInnerHTML={{ __html: dataWithModifiedDateAndLocation ?? (data?.content || '') }}
            ></Box>
          </Box>
          <Divider my="40px" />
          <Testimonials testimonials={data?.testimonials || []} testimonialEnabled={data?.testimonial_enabled} />
          <Faqs faqs={data?.faqs || []} faqEnabled={data?.faq_enabled} />
          {data?.enquiry_form?.enquiry_enabled ? (
            <EnquiryForm slug={data?.slug} enquiryForm={data?.enquiry_form} accountId={data?.account_id} />
          ) : null}
          {customScriptList?.length ? (
            <Box mb="40px">
              <InjectCustomScript widgets={data?.custom_scripts} page={1} isFooter />
            </Box>
          ) : null}
          <Profile onOpen={onPolicyModalOpen} profileInfo={data?.profile} setIsTos={setIsTos} tosPolicies={policy} />
          {!data?.remove_im_branding && <Footer />}
          <PolicyModal isOpen={isPolicyModalOpen} onClose={onPolicyModalClose} isTos={isTos} tosPolicies={policy} />
        </Box>

        {showReportAbuse ? (
          <Link href={reportAbuseLink} color="primary" textDecoration="underline" fontSize="12px">
            Report this page
          </Link>
        ) : null}

        {isLargerThan720 && (
          <Box
            bg={themeMode === 'light' ? '#F3F5F8' : '#3F3F3F'}
            backgroundImage={Pattern}
            pos="fixed"
            right="0"
            w={isLargerThan1080 ? 'calc(50% - 100px)' : 'auto'}
            h="100vh"
            top="0"
          >
            {renderPricing()}
          </Box>
        )}
      </Box>

      <Box pos="fixed" bottom="0" w="100%" p="32px" pb="24px" d={{ base: 'block', md: 'none' }} bg="transparent">
        {/* For Mobile Screens */}
        <Button isFullWidth onClick={onPricingDialogOpen} h="54px" fontSize="18px">
          {data?.payment_button_text}
        </Button>
        <Drawer isOpen={isPricingDialogOpen} placement="bottom" onClose={onPricingDialogClose}>
          <DrawerOverlay />
          <DrawerContent bg="white">
            <Box d="flex" justifyContent="end" p="16px">
              <IconButton
                aria-label="close"
                icon={<CloseCircleIcon color="#a00" />}
                bgColor="white"
                size="sm"
                borderRadius="none"
                onClick={onPricingDialogClose}
              />
            </Box>
            {renderPricing()}
          </DrawerContent>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
