import {
  Box,
  Button,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useClipboard,
  chakra,
  useTheme,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  CheckDoubleIcon,
  CloseCircleIcon,
  CopyIcon,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsAppIcon,
} from '@instamojo/icons';
import { isBrowser } from '../../../utils';

const SharePageModal = ({ isOpen, onClose }) => {
  let pageUrl = '';
  if (isBrowser()) {
    pageUrl = window?.location?.href;
  }
  const { hasCopied, onCopy } = useClipboard(pageUrl);
  const theme = useTheme();
  const colorMode = theme.config.initialColorMode;
  const headingColor = useColorModeValue('#444463', '#fff');
  const pageUrlColor = useColorModeValue('#696982', 'rgba(255, 255, 255, 0.8)');
  const closeBtnColor = useColorModeValue('#DF542F', '#B4B4C1');
  const modelBgColor = useColorModeValue('#fff', '#3D3D3D');

  const iconProps = colorMode === 'dark' ? { color: '#fff' } : {};

  const whatsappUrl = () => {
    return `https://api.whatsapp.com/send?text=${encodeURIComponent(`Hey, look at this smart page! ${pageUrl}`)}`;
  };

  const facebookUrl = () => {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}`;
  };

  const twitterUrl = () => {
    const uri = `${encodeURIComponent(pageUrl)}&text=${encodeURIComponent('This is a smartPage')}`;
    return `https://twitter.com/intent/tweet?url=${uri}`;
  };

  const mailItUrl = () => {
    return `mailto:?subject=${encodeURIComponent('This is Smart Page')}&body=${encodeURIComponent(
      `Pay Here ${pageUrl}`,
    )}`;
  };

  const copyTextProps =
    colorMode === 'light'
      ? {
          color: '#696982',
          bgColor: 'transparent',
          border: '1px solid #EEEEF4', 
        }
      : {
          color: 'rgba(255, 255, 255, 0.8)',
          bgColor: 'transparent',
          border: '1px solid #575757',
        };

  const copyBtnProps =
    colorMode === 'light'
      ? {
          bgColor: '#fff',
          color: '#4E4E91',
          borderColor: '#EEEEF4',
        }
      : {
          bgColor: '#575757',
          color: '#fff',
          borderColor: '#575757',
        };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor={modelBgColor}>
        <Box p="16px" pb="0">
          <Box d="flex" justifyContent="space-between">
            <Box>
              <Heading fontSize="18px" color={headingColor} fontWeight="500" mb="8px">
                Share Page
              </Heading>
            </Box>
            <IconButton
              icon={<CloseCircleIcon color={closeBtnColor} />}
              aria-label="close"
              variant="ghost"
              mt={-1.5}
              onClick={onClose}
            />
          </Box>
        </Box>
        <ModalBody>
          <Box d="flex" justifyContent="space-between" p="16px" mb="24px">
            <Link href={facebookUrl()} d="flex" flexDirection="column" alignItems="center" target="_blank">
              <FacebookIcon {...iconProps} />
              <Text mx="0" mb="0" mt="9px" fontSize="13px" fontWeight={400} color="#696982">
                Facebook
              </Text>
            </Link>
            <Link href={twitterUrl()} d="flex" flexDirection="column" alignItems="center" target="_blank">
              <TwitterIcon {...iconProps} />
              <Text mx="0" mb="0" mt="9px" fontSize="13px" fontWeight={400} color="#696982">
                Twitter
              </Text>
            </Link>
            <Link href={mailItUrl()} d="flex" flexDirection="column" alignItems="center" target="_blank">
              <EmailIcon color="#4E4E91" {...iconProps} />
              <Text mx="0" mb="0" mt="9px" fontSize="13px" fontWeight={400} color="#696982">
                Email
              </Text>
            </Link>
            <Link href={whatsappUrl()} d="flex" flexDirection="column" alignItems="center" target="_blank">
              <WhatsAppIcon {...iconProps} />
              <Text mx="0" mb="0" mt="9px" fontSize="13px" fontWeight={400} color="#696982">
                WhatsApp
              </Text>
            </Link>
          </Box>
          <Box d="flex" alignItems="flex-start" mb="16px">
            <Text
              fontSize="16px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              p="7px 8px"
              flex="1"
              {...copyTextProps}
            >
              <chakra.a
                href={`${pageUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                color={pageUrlColor}
                textDecoration="none"
              >
                {`${pageUrl}`}
              </chakra.a>
            </Text>
            <Button
              {...copyBtnProps}
              variant="outline"
              rounded="none"
              onClick={onCopy}
              fontSize="16px"
              fontWeight={400}
              leftIcon={hasCopied ? <CheckDoubleIcon size={18} /> : <CopyIcon size={16} />}
            >
              {hasCopied ? 'Copied' : 'Copy'}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SharePageModal;
