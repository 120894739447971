import { Box, Heading, Text, Flex, Divider, useColorModeValue, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@instamojo/icons';

import Carousel, { Dots, arrowsPlugin } from '@brainhubeu/react-carousel';

import { ITestimonials } from '../../types';
import { isBrowser } from '../../utils';
import { carouselStyles } from './carousel.styles';

interface TestimonialsProps {
  testimonials: ITestimonials[];
  testimonialEnabled: boolean;
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials, testimonialEnabled }) => {
  const [value, setValue] = useState(() => {
    const defaultIndex = testimonials.findIndex((testimonial) => testimonial.is_default);
    if (defaultIndex > -1) {
      return defaultIndex;
    }
    return 0;
  });

  const dotSelectedColor = useColorModeValue('#444463', '#D0D0D0');
  const dotColor = useColorModeValue('#D0D0D0', 'rgba(208, 208, 208, 0.6)');
  const arrowButtonColor = useColorModeValue('#444463', 'rgba(255, 255, 255, 0.6)');
  const arrowButtonDisabledColor = useColorModeValue('#D6D6DC', 'rgba(214, 214, 220, 0.3)');
  const designationColor = useColorModeValue('#8F8FA1', 'rgba(255, 255, 255, 0.6)');

  if (testimonials.length === 0 || !testimonialEnabled) {
    return null;
  }

  return (
    <>
      <Box css={carouselStyles}>
        <Heading fontSize="24px" color="primary">
          Testimonials
        </Heading>
        <Box
          mx="auto"
          pt="36px"
          pb="12px"
          sx={{
            '.BrainhubCarousel__dots .BrainhubCarousel__dot:before': {
              bgColor: dotColor,
            },
            '.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before': {
              bgColor: dotSelectedColor,
            },
            '.BrainhubCarousel__dots .BrainhubCarousel__dot': {
              padding: '6px',
            },
          }}
        >
          {isBrowser() ? (
            <>
              <Carousel
                value={value}
                onChange={setValue}
                dots={true}
                lazyLoad={true}
                plugins={
                  testimonials.length > 1
                    ? [
                        {
                          resolve: arrowsPlugin,
                          options: {
                            arrowLeft: (
                              <IconButton
                                bgColor={arrowButtonColor}
                                size="sm"
                                rounded="full"
                                aria-label="left"
                                icon={<ChevronLeftIcon />}
                              />
                            ),
                            arrowLeftDisabled: (
                              <IconButton
                                size="sm"
                                bgColor={arrowButtonDisabledColor}
                                rounded="full"
                                aria-label="left"
                                isDisabled
                                icon={<ChevronLeftIcon />}
                              />
                            ),
                            arrowRight: (
                              <IconButton
                                bgColor={arrowButtonColor}
                                size="sm"
                                rounded="full"
                                aria-label="right"
                                icon={<ChevronRightIcon />}
                              />
                            ),
                            arrowRightDisabled: (
                              <IconButton
                                size="sm"
                                bgColor={arrowButtonDisabledColor}
                                rounded="full"
                                aria-label="right"
                                isDisabled
                                icon={<ChevronRightIcon />}
                              />
                            ),
                            addArrowClickHandler: true,
                          },
                        },
                      ]
                    : []
                }
                animationSpeed={1000}
              >
                {testimonials.map((testimonial, index) => {
                  return (
                    <Flex
                      key={index}
                      direction="column"
                      align="center"
                      textAlign="center"
                      px={{ base: '8px', md: '16px' }}
                      pb="16px"
                    >
                      <Text fontSize="16px" fontStyle="italic" fontWeight="400">
                        &ldquo;{testimonial.content}&rdquo;
                      </Text>
                      <Box>
                        <Text fontWeight="500" fontSize="13px" mb="6px">
                          {testimonial.name}
                        </Text>
                        <Text fontWeight="400" fontSize="12px" mb="0" color={designationColor}>
                          {testimonial.designation}
                        </Text>
                      </Box>
                    </Flex>
                  );
                })}
              </Carousel>
              <Dots value={value} onChange={setValue} number={testimonials.length} />
            </>
          ) : null}
        </Box>
      </Box>
      <Divider my="48px" />
    </>
  );
};

export default Testimonials;
