import 'react-quill/dist/quill.snow.css';
import { chakra, Text, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import Editor from './Editor';

interface PolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
  tosPolicies: object;
  isTos: boolean;
}

const PolicyModal: React.FC<PolicyModalProps> = ({ isOpen, onClose, tosPolicies, isTos }) => {
  const policyToShow = isTos
    ? ['privacy', 'terms-and-conditions', 'shipping-and-delivery', 'disclaimer']
    : ['cancel-and-refund'];
  const mandatoryPolicy = ['privacy', 'terms-and-conditions', 'cancel-and-refund'];
  return (
    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside" trapFocus={false}>
      <ModalOverlay />
      <ModalContent maxWidth="360px" height="calc(80vh - 60px)">
        <ModalBody p="14px">
          <Text color="#4D4D6E" paddingTop="8px" fontSize="18px" mb={isTos ? '16px' : '6px'}>
            {isTos ? 'Terms of Service' : 'Cancellation and Refund Policy'}
          </Text>
          {Object.values(tosPolicies).map((tosPolicy) => {
            return (
              <>
                {(tosPolicy.status || mandatoryPolicy.includes(tosPolicy.type)) &&
                policyToShow.includes(tosPolicy.type) ? (
                  <chakra.div
                    key={tosPolicy}
                    style={{
                      padding: '0',
                      fontSize: '14px',
                    }}
                  >
                    {isTos ? <chakra.b color="#696982">{tosPolicy.name}</chakra.b> : null}
                    <chakra.div
                      sx={{
                        '.ql-tooltip': {
                          display: 'none',
                        },
                        p: {
                          fontSize: '14px',
                          marginBottom: '0px',
                        },
                        '.ql-editor': {
                          padding: '0px',
                        },
                        '.ql-editor ol li': {
                          paddingTop: '.5em',
                        },
                        '.ql-editor li.ql-indent-1:not(.ql-direction-rtl)': {
                          paddingLeft: '2em',
                        },
                        '.ql-editor li.ql-indent-2:not(.ql-direction-rtl)': {
                          paddingLeft: '3em',
                        },
                        '.ql-editor li.ql-indent-3:not(.ql-direction-rtl)': {
                          paddingLeft: '4.5em',
                        },
                        ol: {
                          paddingLeft: '0',
                        },
                        ul: {
                          paddingLeft: '0',
                        },
                      }}
                      color="#696982"
                      py="10px"
                    >
                      <Editor 
                        value={(tosPolicy?.content).replace('<p><br></p>', '') + tosPolicy?.additionalContent}
                        isReadOnly={true}
                      />
                    </chakra.div>
                  </chakra.div>
                ) : null}
              </>
            );
          })}
        </ModalBody>
        <ModalFooter padding="0">
          <Button variant="solid" bgColor="#4E4E91" color="white" onClick={onClose} width="360px" padding="10px">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PolicyModal;
