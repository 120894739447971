import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CheckCircleOutlineIcon, CalendlyIcon } from '@instamojo/icons';
import Rupee from '@instamojo/rupee';
import { getFormattedDateWithTime } from '@instamojo/format-utils';
import { getSmartPageApi, getSmartPageRedirectionApi } from '../../api';
import PageNotFound from '../../components/PageNotFound';
import ThemeProvider from '../../theme/ThemeProvider';
import {
  Container,
  Box,
  Heading,
  Link,
  Divider,
  Text,
  chakra,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import PageLoader from '../../components/PageLoader';
import Footer from './Footer';
import InjectScript from '../../components/InjectScript';
import { trackEvent } from '../../utils/analytics';
import { isFree } from '../../utils';
import InjectCustomScript from '../../components/InjectCustomScript';
import { useEffect } from 'react';

const maskMobileNumber = (num) => {
  let number = String(num);
  if (number[0] !== '+') {
    number = '+91' + number;
  }
  const len = number.length;
  return number.slice(0, 4) + 'X'.repeat(len - 7) + number.slice(-3);
};

const ThankYou = () => {
  const { search } = useLocation();
  const { slug } = useParams();
  const [isLargerThan500] = useMediaQuery('(min-width: 500px)');
  const token = new URLSearchParams(search).get('token');
  const orderId = new URLSearchParams(search).get('order_id');
  const { data: pageData, isLoading } = useQuery('smartpage', () => getSmartPageApi(slug));
  const {
    data,
    isLoading: isRedirectionDataLoading,
    isError,
  } = useQuery('redirectionData', () =>
    getSmartPageRedirectionApi({
      slug: pageData.slug,
      orderId,
      token,
    }),
  );
  const {
    isOpen: isCalendlyModalOpen,
    onOpen: onCalendlyModalOpen,
    onClose: onCalendlyModalClose,
  } = useDisclosure({
    id: 'calendlyModal',
  });
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  useEffect(() => {
    if (window.localStorage.getItem('orderId') && data) {
      trackEvent('Cart Converted', {
        amount: data?.order_details?.final_amount,
        coupon_applied: window.localStorage.getItem('emailDiscount') === 'yes' ? true : false,
        buyer_id: data?.order_details?.email,
        offer_type: 'smart_page_plan',
        order_id: window.localStorage.getItem('orderId'),
        username: pageData?.username,
      })
      window.localStorage.removeItem('orderId')
    }
    /* eslint-disable-next-line */
  }, [data])

  if (isLoading || isRedirectionDataLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return <PageNotFound />;
  }

  const orderDetails = data?.order_details;
  const dayColor = pageData?.custom_theme_enabled ? pageData?.custom_brand_colour : pageData?.day_color;
  const calendlyEvent = pageData?.page_widgets?.calendly?.widget_params?.events?.filter(
    (item) => item.is_post_purchase_page,
  );
  const isPaidProduct = !isFree(orderDetails?.final_amount);
  const handleBookYourSlot = () => {
    onCalendlyModalOpen();
    trackEvent('Clicked Item', {
      item_name: 'calendly_widget',
    });
  };

  return (
    <ThemeProvider themeMode="light" dayColor={dayColor} nightColor={pageData?.night_color}>
      <InjectScript widgets={pageData?.page_widgets} page={3} />
      {pageData?.custom_scripts?.length > 0 && <InjectCustomScript widgets={pageData?.custom_scripts || []} page={3} />}
      <Box maxW="1040px" m="auto" px={{ base: '16px', md: 0 }}>
        <Box d="flex" justifyContent="flex-start" h="56px" alignItems="center">
          <Link href={`/${slug ? slug : ''}`}>
            <Heading fontSize="20">{pageData.title}</Heading>
          </Link>
        </Box>
      </Box>
      <Divider />
      <Container
        mt="40px"
        minW={{ base: '100%', md: '1080px' }}
        sx={{
          p: {
            marginBottom: '0px',
          },
        }}
      >
        {data?.thank_you_msg ? (
          <Box dangerouslySetInnerHTML={{ __html: data?.thank_you_msg }}></Box>
        ) : (
          <Box d={{ base: 'block', md: 'flex' }} alignItems="flex-end" color="#696982">
            <Box mr={{ base: '0', md: '64px' }}>
              <Box color="#66bb6a" d="flex" justifyContent="flex-start" alignItems="center" mb="16px">
                <CheckCircleOutlineIcon size="32px" />
                <Text fontSize="24px" ml="8px" mb="0" fontWeight="500">
                  Thank you for your order
                </Text>
              </Box>
              <Text> {isPaidProduct ? 'We’ve received your payment and processing your order now.' : ''}</Text>
              <Box p="16px" border="1px solid #eeeef4" borderRadius="4px" mb="16px">
                <Heading fontSize="16" fontWeight="normal" mb="20px">
                  Order Details
                </Heading>
                <Box d={isLargerThan500 ? 'flex' : 'block'} justifyContent="space-between" fontSize="14">
                  <Box>
                    <Text fontSize="14px" m="0">
                      Payment ID
                    </Text>
                    <Text fontSize="14px" m="0" fontWeight="600">
                      {orderDetails?.payment_id}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="14px" m="0">
                      Paid On
                    </Text>
                    <Text fontSize="14px" m="0" fontWeight="600">
                      {getFormattedDateWithTime(orderDetails?.completion_time)}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Text fontSize="14px">
                A confirmation email has been sent to
                <chakra.span fontWeight="600" mx="4px">
                  {orderDetails?.email}
                </chakra.span>
                . You will receive all order related status updates on the mobile phone number
                <chakra.span fontWeight="600" mx="4px">
                  {maskMobileNumber(orderDetails?.number)}
                </chakra.span>
              </Text>
            </Box>
            <Box
              p="16px"
              fontSize="14px"
              border="1px solid #eeeef4"
              borderRadius="4px"
              mb={isPaidProduct ? '16px' : '70px'}
              minW="250px"
            >
              {isPaidProduct ? (
                <>
                  <Text fontSize="14px" textAlign="center" mb="8px" color="#8f8fa1">
                    Payment Method
                  </Text>
                  <Text fontSize="14px" textAlign="center" mb="24px">
                    {orderDetails?.payment_method}
                  </Text>
                </>
              ) : null}
              <Text fontSize="14px" textAlign="center" mb="8px" color="#8f8fa1">
                Order Summary
              </Text>
              <Box d="flex" justifyContent="space-between" mb="8px">
                <chakra.span>
                  Subtotal ({orderDetails?.purpose} x {orderDetails?.quantity})
                </chakra.span>
                <Rupee value={orderDetails?.subtotal} />
              </Box>
              {orderDetails?.discount_amount > 0 ? (
                <Box d="flex" justifyContent="space-between" mb="8px">
                  <chakra.span>Discount</chakra.span>
                  <Rupee value={orderDetails?.discount_amount} />
                </Box>
              ) : null}
              <Divider mb="8px" />
              <Box d="flex" fontWeight="600" justifyContent="space-between">
                <chakra.span>Total</chakra.span>
                <Rupee value={orderDetails?.final_amount} />
              </Box>
            </Box>
          </Box>
        )}

        {calendlyEvent?.length ? (
          <Box
            p={{ base: '16px', md: '8px' }}
            border={{ base: 'none', md: '1px solid #E1FEFF' }}
            borderRadius="4px"
            w={{ base: '100%', md: '240px' }}
            bgColor="#E1FEFF"
          >
            <Stack direction="row" justifyContent="center">
              <Button leftIcon={<CalendlyIcon />} variant="ghost" onClick={handleBookYourSlot}>
                Book your slot now
              </Button>
            </Stack>
            <Modal isOpen={isCalendlyModalOpen} onClose={onCalendlyModalClose}>
              <ModalOverlay />
              <ModalContent maxW={isLargerThan600 ? '648px' : '100%'} h="636px" position="relative">
                <ModalCloseButton
                  color="white"
                  fontSize="20px"
                  zIndex="9999"
                  position="absolute"
                  right="0"
                  top="0"
                  mt="-40px"
                  mr={{ base: 0, md: '-40px' }}
                  outline="none"
                  _focus={{ outline: 'none' }}
                  _hover={{ bg: 'transparent' }}
                  onClick={() =>
                    trackEvent('Clicked Item', {
                      item_name: 'closed_calendly_modal',
                    })
                  }
                />
                <ModalBody p="0">
                  <iframe
                    src={calendlyEvent[0]?.scheduling_url}
                    width="100%"
                    height="100%"
                    title="calendlyEventModal"
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        ) : null}
        {pageData?.custom_scripts?.length > 0 && (
          <InjectCustomScript widgets={pageData?.custom_scripts || []} page={3} isFooter />
        )}
        <Divider borderColor="#DF542F" my="8" />
        {!pageData?.remove_im_branding && <Footer />}
      </Container>
    </ThemeProvider>
  );
};

export default ThankYou;
