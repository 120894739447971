import { Switch, Route } from 'react-router-dom';

import Home from './containers/Home';
import Checkout from './containers/Checkout';
import ThankYou from './containers/ThankYou';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={'/'} component={Home} />
      <Route exact path={'/checkout'} component={Checkout} />
      <Route exact path={'/thank-you'} component={ThankYou} />
      <Route exact path={'/:slug/preview'} component={Home} />
      <Route exact path={'/:slug/thank-you'} component={ThankYou} />
      <Route exact path={'/:slug/checkout'} component={Checkout} />
      <Route exact path={'/:slug'} component={Home} />
    </Switch>
  );
};

export default Routes;
