import { useParams, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { getSmartPageApi, getSmartPagePreviewApi } from '../api';

const IsPreviewPage = (): boolean => {
  const { pathname } = useLocation();
  const { slug } = useParams();
  return '/' + slug + '/preview' === pathname;
};

const useSmartPage = () => {
  const { search } = useLocation();
  const { slug } = useParams();
  const isPreview = IsPreviewPage();
  const token = (isPreview && new URLSearchParams(search).get('token')) || null;

  const smartPage = useQuery<any, any>('smartpage', () => getSmartPageApi(slug), {
    enabled: !isPreview,
  });

  const smartpagePreviewData = useQuery<any>('smartpagePreview', () => getSmartPagePreviewApi({ slug, token }), {
    enabled: isPreview,
  });

  if (!isPreview) {
    return smartPage;
  } else {
    return { ...smartpagePreviewData, data: smartpagePreviewData?.data?.preview_data };
  }
};

const useSmartPageQueryData = () => {
  const queryClient = useQueryClient();
  const isPreview = IsPreviewPage();

  if (isPreview) {
    const { preview_data }: { preview_data: any } = queryClient.getQueryData('smartpagePreview');
    return preview_data;
  } else {
    return queryClient.getQueryData('smartpage');
  }
};

export { IsPreviewPage, useSmartPage, useSmartPageQueryData };
