import { isBrowser } from '.';

declare global {
  interface Window {
    context: any;
  }
}

let mixpanel = null;

interface MixpanelOptions {
  send_immediately?: boolean;
  transport?: 'xhr' | 'sendBeacon';
}

export const trackEvent = (
  eventName: string,
  eventProperties: Record<string, unknown> | undefined,
  options?: MixpanelOptions,
  callback?: () => void,
): void => {
  if (!isBrowser()) {
    return;
  }
  try {
    if (!mixpanel) {
      import('mixpanel-browser').then((Mixpanel) => {
        mixpanel = Mixpanel;
        mixpanel?.default?.init(window?.context?.MIXPANEL_TOKEN);
        console.log('mixpanel initialised');
        mixpanel?.default?.track(eventName, eventProperties, options, callback);
      });
    } else {
      console.log('mixpanel tracking');
      mixpanel?.default?.track(eventName, eventProperties, options, callback);
    }
  } catch (e) {
    console.error('[Failed] Analytics:', e);
  }
};
