import {
  Box,
  chakra,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useDisclosure,
  Link,
  Text,
} from '@chakra-ui/react';
import { LockIcon, ChevronRightIcon } from '@instamojo/icons';
import { useEffect, useState, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, Redirect } from 'react-router-dom';
import { getDisabledButtonProps, isFree } from '../../utils';
import { useSmartPageQueryData } from '../../hooks';
import { useLocation } from 'react-router-dom';
import {
  getSmartPageApi,
  getSmartPageRedirectionApi,
  placeOrderApi,
  getProductDetailsFromAbandonedPayment,
} from '../../api';
import PageLoader from '../../components/PageLoader';
import ThemeProvider from '../../theme/ThemeProvider';
import CustomerInfo from './CustomerInfo';
import DeliveryInfo from './DeliveryInfo';
import OrderSummary from './OrderSummary';
import PaymentModal from './PaymentModal';
import { isBrowser } from '../../utils';
import PageNotFound from '../../components/PageNotFound';
import { trackEvent } from '../../utils/analytics';
import InjectScript from '../../components/InjectScript';
import _ from 'lodash';
import InjectCustomScript from '../../components/InjectCustomScript';

interface ParamTypes {
  slug: string;
}

const Checkout = () => {
  const { slug } = useParams<ParamTypes>();
  const [activeStep, setActiveStep] = useState('customerInfo');
  const [customerInfo, setCustomerInfo] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [triggerPay, setTriggerPay] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [notification, setNotification] = useState(false);
  const [showPaymentModalCloseButton, setShowPaymentModalCloseButton] = useState(true);
  const { data: pageData, isLoading, isError } = useQuery('smartpage', () => getSmartPageApi(slug));
  const shouldCollectMoreInfoFromCustomer = pageData?.customer_info_enabled;
  const showDeliveryInfo =
    shouldCollectMoreInfoFromCustomer &&
    (pageData?.customer_info?.ask_shipping_address || pageData?.customer_info?.ask_gst_details);
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const orderId = queryParams?.get('order');
  const code = queryParams?.get('code');
  const token = queryParams?.get('token')
  const [finalDiscountCode, setFinalDiscountCode] = useState('');
  const [isPriceLoading, setIsPriceLoading] = useState(true);
  const [redirectToHome, setRedirectToHome] = useState(false);
  /* eslint-disable-next-line */
  const [localStorageData, setLocalStorageData] = useState({});
  const {
    data: checkoutDataFromLocalStorage,
    isLoading: checkoutDetailsIsLoading,
    isError: checkoutDetailsIsError,
  } = useQuery(
    ['smartPageDetails', orderId],
    () =>
      getProductDetailsFromAbandonedPayment({
        slug,
        orderId,
        setCustomerInfo,
        code,
        setFinalDiscountCode,
        setDeliveryInfo,
        token
      }),
    {
      enabled: !!orderId,
    },
  );

  useEffect(() => {
    if (pageData?.pricing && checkoutDataFromLocalStorage) {
      setIsPriceLoading(true);
      const { data } = checkoutDataFromLocalStorage;
      const choosenProduct = pageData?.pricing.filter((product) => product.purpose === data.productName)[0];
      const amount = choosenProduct?.discounted_amount 
          ? choosenProduct?.discounted_amount * (data?.productQuantity) 
          : choosenProduct?.flexible_amount_allowed
          ? data.finalAmount
          : choosenProduct.amount * (data?.productQuantity);

      const updatedData = {
        ...data,
        amount: amount,
        productAmount: choosenProduct?.discounted_amount 
          ? choosenProduct?.discounted_amount 
          : choosenProduct?.amount,
        finalAmount: amount,
        selectedOption: {
          ...data.selectedOption,
          amount: choosenProduct?.amount,
          discounted_amount: choosenProduct?.discounted_amount,
          flexible_amount_allowed: choosenProduct?.flexible_amount_allowed
        },
      };

      if (isBrowser() && choosenProduct) {
        window.localStorage.setItem('abandonedPaymentCheckoutData', JSON.stringify(updatedData));
        setLocalStorageData(updatedData);
      }
      setIsPriceLoading(false);
      if (!choosenProduct?.amount) setRedirectToHome(true);
      if (pageData?.customer_info?.ask_gst_details || pageData?.customer_info?.ask_shipping_address) setActiveStep('deliveryInfo');

      if (orderId) {
        const newUrl = `${location.pathname}`;
        window.history.replaceState(null, '', newUrl);
      }
    } else {
      setIsPriceLoading(false);
    }
    /* eslint-disable-next-line */
  }, [pageData, checkoutDataFromLocalStorage]);

  let checkoutData: any = {};

  if (isBrowser()) {
    checkoutData = orderId
      ? JSON.parse(window.localStorage.getItem('abandonedPaymentCheckoutData'))
      : JSON.parse(window.localStorage.getItem('checkoutData'));
  }

  const smartPage = useSmartPageQueryData();
  const { isOpen: isPaymentModalOpen, onOpen: onPaymentModalOpen, onClose: onPaymentModalClose } = useDisclosure();

  const { data: placeOrderData, mutate: placeOrder } = useMutation(placeOrderApi, {
    onSuccess: (data) => {
      setDisabled(false);
      if (data?.payment_link) {
        onPaymentModalOpen();
      } else if (data?.is_free_product) {
        setDisabled(true);
        getPageRedirectionInfo({
          slug: pageData.slug,
          orderId: data.order_id,
          token: data.token,
        });
      }
      setFormErrors(null);
    },
    onError: ({
      response: {
        status,
        data: { message },
      },
    }) => {
      const errorMessage = _.mapKeys(message, (v, k) => _.camelCase(k));
      setFormErrors(errorMessage);
      setDisabled(false);
      // Error notification will only be shown on free product claim request failure
      // It will not be displayed for validation errors
      if (isFree(checkoutData?.amount) && status > 400) {
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
        }, 5000);
      }
    },
  });

  const { mutate: getPageRedirectionInfo } = useMutation(getSmartPageRedirectionApi, {
    onSuccess: (data, variables) => {
      setTimeout(() => {
        if (pageData?.redirection_enabled) {
          window.location.href = data.redirect_url;
        } else {
          if (slug) {
            window.location.href = `${window.location.origin}/${slug}/thank-you?order_id=${variables.orderId}&token=${variables.token}`;
          } else {
            window.location.href = `${window.location.origin}/thank-you?order_id=${variables.orderId}&token=${variables.token}`;
          }
        }
        setDisabled(false);
      }, 2000);
    },
    onError: () => {
      setDisabled(false);
    },
  });

  useEffect(() => {
   if (!orderId)  {
    window.localStorage.removeItem('orderId')
    window.localStorage.removeItem('code')
   }
  }, [orderId])

  useEffect(() => {
    async function handleOnMessage(e) {
      if (e?.data?.status === 'success') {
        setShowPaymentModalCloseButton(false);
        getPageRedirectionInfo({
          slug: pageData.slug,
          orderId: placeOrderData.order_id,
          token: placeOrderData.token,
        });
      }
      if (e?.data === 'onRequestClose') {
        onPaymentModalClose();
      }
      if (e?.data?.status === 'failure') {
        setShowPaymentModalCloseButton(false);
      }
    }
    if (isBrowser()) {
      window.addEventListener('message', handleOnMessage);
    }
    return () => {
      window.removeEventListener('message', handleOnMessage);
    };
  });

  const handleCustomerInfo = async (values) => {
    trackEvent('Entered Buyer Details', {
      offer_id: pageData?.slug,
      amount: checkoutData?.finalAmount,
      seller_username: pageData?.username,
      seller_account_id: pageData?.account_id,
      is_smart_page_order: true,
    });
    setCustomerInfo(values);
    if (!showDeliveryInfo) {
      setTriggerPay(true);
    } else {
      setActiveStep('deliveryInfo');
    }
  };

  const handleDeliveryInfo = (values) => {
    trackEvent('Entered Delivery Info', {
      offer_id: pageData?.slug,
      amount: checkoutData?.finalAmount,
      seller_username: pageData?.username,
      seller_account_id: pageData?.account_id,
      is_smart_page_order: true,
    });
    setDeliveryInfo(values);
    setTriggerPay(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePay = () => {
    const additionalFields = {
      additional_custom_fields: customerInfo?.customFields,
      ...deliveryInfo,
      ...(deliveryInfo?.billing_same_as_shipping && {
        billing_address: deliveryInfo?.shipping_address,
      }),
    };
    const orderParams = {
      slug,
      name: `${customerInfo.firstName} ${customerInfo.lastName}`,
      email: customerInfo.email,
      phoneNumber: customerInfo.phoneNumber,
      amount: checkoutData?.amount,
      finalAmount: checkoutData?.finalAmount,
      discountCode: checkoutData?.discountCode,
      productQuantity: checkoutData?.productQuantity,
      productName: checkoutData?.productName,
      additionalFields: additionalFields,
      buyer_whatsapp_consent: customerInfo?.buyer_whatsapp_consent,
    };

    trackEvent('Created Order', {
      amount: orderParams.finalAmount,
      buyer_id: '',
      buyer_name: orderParams.name,
      buyer_email: orderParams.email,
      buyer_phone: orderParams.phoneNumber,
      username: pageData?.username,
      account_id: pageData?.account_id,
      order_id: '',
      visitor_cookie_id: '',
      request_id: '',
      is_smart_page_order: true,
    });
    setDisabled(true);
    placeOrder({orderParams, isWhatsappCheckEligible: (smartPage?.ask_buyer_whatsapp_consent || false) });
  };

  useEffect(() => {
    if (customerInfo && triggerPay) {
      if (!showDeliveryInfo) {
        handlePay();
        setTriggerPay(false);
      }
      if (deliveryInfo) {
        handlePay();
        setTriggerPay(false);
      }
    }
  }, [customerInfo, deliveryInfo, showDeliveryInfo, triggerPay, handlePay]);

  if (isLoading || checkoutDetailsIsLoading || isPriceLoading) {
    return <PageLoader />;
  }

  if (isError || checkoutDetailsIsError) {
    return <PageNotFound />;
  }

  if (!checkoutData?.productName || redirectToHome) {
    return <Redirect to={`/${slug}`} />;
  }

  const customScriptList = pageData?.custom_scripts ? pageData?.custom_scripts.filter((item) => item.page_type.includes(2) && item.section === 'FOOTER' && item.enabled) : [];
  const dayColor = pageData?.custom_theme_enabled ? pageData?.custom_brand_colour : pageData?.day_color;
  const themeMode = pageData?.theme_mode === 2 ? 'dark' : 'light';
  // Consider the payment enabled as True if payment_enabled flag is missing
  const paymentEnabled = pageData?.payment_enabled ?? true;
  const disabledButtonProps = getDisabledButtonProps(paymentEnabled);
  const buttonEnabled = paymentEnabled && !disabled;
  return (
    <ThemeProvider fontFamily="Noto Sans" themeMode={themeMode} dayColor={dayColor} pageName="checkout" nightColor={pageData?.night_color}>
      <InjectScript widgets={pageData?.page_widgets} page={2} />
      <InjectCustomScript widgets={(pageData?.custom_scripts || [])} page={2}/>
      {notification ? (
        <Box position="absolute" opacity="1" bgColor="#F4CECE" width="100%">
          <Text color="#C21B1B" textAlign="center" p="5px 0 8px" m="0px" fontSize="12px">
            There was a technical issue. Please retry again
          </Text>
        </Box>
      ) : null}
      <Box maxW="1040px" m="auto" px={{ base: '16px', md: 0 }}>
        <Box d="flex" justifyContent="space-between" h="56px" alignItems="center">
          <Link href={`/${slug ? slug : ''}`} maxWidth={{ base: 'calc(100% - 120px)', md: 'auto' }}>
            <Heading
              fontSize={{ base: '16px', md: '20px' }}
              whiteSpace={{ base: 'nowrap', md: 'normal' }}
              overflow={{ base: 'hidden', md: 'inherit' }}
              textOverflow={{ base: 'ellipsis', md: 'inherit' }}
            >
              {pageData.title}
            </Heading>
          </Link>
          <Box d="flex" alignItems="center">
            <LockIcon size="14" />
            <chakra.span fontSize="13" ml="4px">
              Secure Checkout
            </chakra.span>
          </Box>
        </Box>
      </Box>
      <Box py="16px" bgColor="#f3f5f8" h="52px">
        <Box maxW="1040px" m="auto" px={{ base: '16px', md: 0 }}>
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon size="14" />} lineHeight="initial">
            <BreadcrumbItem>
              <BreadcrumbLink
                fontSize="13"
                onClick={() => setActiveStep('customerInfo')}
                _hover={{ textDecoration: 'none' }}
                fontWeight="500"
              >
                Customer Info
              </BreadcrumbLink>
            </BreadcrumbItem>
            {showDeliveryInfo && (
              <BreadcrumbItem isCurrentPage={activeStep === 'deliveryInfo'}>
                <BreadcrumbLink
                  fontSize="13"
                  _hover={{ textDecoration: 'none' }}
                  fontWeight={activeStep === 'deliveryInfo' ? '500' : 'normal'}
                >
                  Delivery Info
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Box>
      </Box>
      <Box maxW="1040px" m="auto" px={{ base: '16px', md: 0 }}>
        {customScriptList?.length ? 
          <Box mt="24px">
            <InjectCustomScript widgets={pageData?.custom_scripts || []} page={2} isFooter />
          </Box>
         : null}
        <Box mt="32px" d="flex" flexDirection={{ base: 'column', md: 'row' }}>
          <Box flex="1" mr={{base: "0", md: '24px'}} order={{ base: 2, md: 1 }}>
            {activeStep === 'customerInfo' && (
              <CustomerInfo
                handleCustomerInfo={handleCustomerInfo}
                initValues={customerInfo}
                formErrors={formErrors}
                amount={checkoutData?.amount}
                disabled={!buttonEnabled}
                disabledButtonProps={disabledButtonProps}
              />
            )}
            {activeStep === 'deliveryInfo' && (
              <DeliveryInfo
                handleDeliveryInfo={handleDeliveryInfo}
                handleActiveStep={setActiveStep}
                initValues={deliveryInfo}
                amount={checkoutData?.amount}
                disabled={!buttonEnabled}
                disabledButtonProps={disabledButtonProps}
              />
            )}
          </Box>
          <Box w={{ base: '100%', md: '300px' }} order={{ base: 1, md: 2 }}>
            <OrderSummary finalDiscountCode={finalDiscountCode} orderId={orderId} />
          </Box>
        </Box>
      </Box>
      {placeOrderData && (
        <PaymentModal
          isOpen={isPaymentModalOpen}
          onClose={onPaymentModalClose}
          paymentLink={placeOrderData?.payment_link}
          showCloseButton={showPaymentModalCloseButton}
        />
      )}
    </ThemeProvider>
  );
};

export default Checkout;
