import { Box, Image, Text, Link, useColorModeValue } from '@chakra-ui/react';
import InstamojoLogo from '../../assets/InstamojoLogo.svg';
import InstamojoLogoLight from '../../assets/InstamojoLogoLight.svg';

const Footer = () => {
  const logo = useColorModeValue(InstamojoLogo, InstamojoLogoLight);
  const textColor = useColorModeValue('#696982', 'rgba(255, 255, 255, 0.6)');
  return (
    <Box mb={{ base: '110px', md: '0' }}>
      <Image src={logo} alt="Instamojo" mb="2" />
      <Text fontSize="14" color={textColor}>
        Want to create landing pages for your business?
        <br />
        Visit{' '}
        <Link
          fontWeight="600"
          color="primary"
          textDecoration="underline"
          href="https://www.instamojo.com/smart-landing-page-builder?utm_source=merchant-smartpage-referral"
          target="_blank"
        >
          Instamojo Smart Pages
        </Link>{' '}
        and get started!
      </Text>
    </Box>
  );
};

export default Footer;
